import { Box, Button, Grid, Modal, Typography } from '@mui/material'
import TextField from '@mui/material/TextField'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { createSubscription } from '../../api/subscription'
import { toast } from 'react-hot-toast'
import NumberInputField from '../../components/NumberInputField'
import { useFormik } from 'formik'
import { COLORS } from '../../constants'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: { xs: '343px', md: '428px' },
  bgcolor: COLORS.background,
  borderRadius: { xs: '5px', md: '10px' },
  boxShadow: 24,
  p: { xs: '16px', sm: '20px', md: '24px' }
}

export default function CreateSubscription({ open, handleClose, company }) {
  const formik = useFormik({
    initialValues: {
      amountPaid: 0,
      endDate: new Date(),
      startDate: new Date(),
      numberOfUsers: 1
    },
    onSubmit: async (values) => {
      try {
        await createSubscription({
          amountPaid: values.amountPaid,
          numberOfUsers: values.numberOfUsers,
          startDate: values.startDate,
          endDate: values.endDate,
          companyId: company.id
        })
        toast.success('تم تجديد الاشتراك بنجاح')
        handleClose()
      } catch (error) {
        console.log(error)
        toast.error(error.message)
      }
    }
  })

  return (
    <Box>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Grid container direction={'column'} spacing={{ xs: '12px', md: 2 }}>
            <Grid item textAlign={'center'}>
              <Typography
                id="modal-modal-title"
                component="h1"
                variant="h5"
                sx={{
                  fontSize: { xs: '16px', md: '18px' },
                  color: 'text.secondary',
                  fontWeight: 'bold'
                }}
              >
                تجديد الاشتراك
              </Typography>
            </Grid>

            <Grid item>
              <Grid container justifyContent={'space-between'}>
                <Typography
                  sx={{
                    color: 'text.secondary',
                    fontSize: '14px',
                    fontWeight: '600'
                  }}
                >
                  تاريخ بداية الاشتراك الحالي
                </Typography>

                <Typography
                  sx={{
                    fontSize: '14px',
                    fontWeight: '600',
                    color: 'text.black'
                  }}
                >
                  {company.activeSubscriptionStartDate}
                </Typography>
              </Grid>
            </Grid>

            <Grid item>
              <Grid container justifyContent={'space-between'}>
                <Typography
                  sx={{
                    color: 'text.secondary',
                    fontSize: '14px',
                    fontWeight: '600'
                  }}
                >
                  تاريخ انتهاء الاشتراك الحالي
                </Typography>

                <Typography
                  sx={{
                    fontSize: '14px',
                    fontWeight: '600',
                    color: 'text.black'
                  }}
                >
                  {company.activeSubscriptionEndDate}
                </Typography>
              </Grid>
            </Grid>

            <Grid item>
              <Grid container justifyContent={'space-between'}>
                <Typography
                  sx={{
                    color: 'text.secondary',
                    fontSize: '14px',
                    fontWeight: '600'
                  }}
                >
                  عدد المستخدمين الحالين
                </Typography>

                <Typography
                  sx={{
                    fontSize: '14px',
                    fontWeight: '600',
                    color: 'text.black'
                  }}
                >
                  {company.activeSubscriptionNumberOfUsers}
                </Typography>
              </Grid>
            </Grid>

            <Grid item>
              <Grid
                container
                alignItems={'center'}
                justifyContent={'space-between'}
                padding={0}
              >
                <Grid item xs={12} marginBottom={{ xs: '6px', md: '8px' }}>
                  <Typography
                    sx={{
                      color: 'text.secondary',
                      fontSize: '14px',
                      fontWeight: '600'
                    }}
                  >
                    {' '}
                    بداية الاشتراك الجديد
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sx={{
                    color: 'black'
                  }}
                >
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      value={formik.values.startDate}
                      onChange={(newValue) => {
                        formik.setFieldValue('startDate', newValue)
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          sx={{
                            color: 'text.black',
                            width: '100%',
                            '& .MuiOutlinedInput-root': {
                              height: { xs: '40px', md: '52px' },
                              color: 'text.black'
                            }
                          }}
                        />
                      )}
                      inputFormat="yyyy/MM/dd"
                    />
                  </LocalizationProvider>
                </Grid>
              </Grid>
            </Grid>

            <Grid item>
              <Grid
                container
                alignItems={'center'}
                justifyContent={'space-between'}
                padding={0}
              >
                <Grid item xs={12} marginBottom={{ xs: '6px', md: '8px' }}>
                  <Typography
                    sx={{
                      color: 'text.secondary',
                      fontSize: '14px',
                      fontWeight: '600'
                    }}
                  >
                    {' '}
                    انتهاء الاشتراك الجديد
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sx={{
                    color: 'black'
                  }}
                >
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      value={formik.values.endDate}
                      onChange={(newValue) => {
                        formik.setFieldValue('endDate', newValue)
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          sx={{
                            color: 'text.black',
                            width: '100%',
                            '& .MuiOutlinedInput-root': {
                              height: { xs: '40px', md: '52px' },
                              color: 'text.black'
                            }
                          }}
                        />
                      )}
                      inputFormat="yyyy/MM/dd"
                    />
                  </LocalizationProvider>
                </Grid>
              </Grid>
            </Grid>

            <Grid item>
              <Grid
                container
                alignItems={'center'}
                justifyContent={'space-between'}
                padding={0}
              >
                <Grid item xs={12} marginBottom={{ xs: '6px', md: '8px' }}>
                  <Typography
                    sx={{
                      color: 'text.secondary',
                      fontSize: '14px',
                      fontWeight: '600'
                    }}
                  >
                    عدد المستخدمين الجديد
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sx={{
                    color: 'black'
                  }}
                >
                  <NumberInputField
                    placeholder="عدد المستخدمين الجديد"
                    name={'numberOfUsers'}
                    value={formik.values.numberOfUsers}
                    onChange={(name, newValue) =>
                      formik.setFieldValue(name, newValue)
                    }
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item>
              <Grid
                container
                alignItems={'center'}
                justifyContent={'space-between'}
                padding={0}
              >
                <Grid item xs={12} marginBottom={{ xs: '6px', md: '8px' }}>
                  <Typography
                    sx={{
                      color: 'text.secondary',
                      fontSize: '14px',
                      fontWeight: '600'
                    }}
                  >
                    المبلغ المدفوع
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sx={{
                    color: 'black'
                  }}
                >
                  <NumberInputField
                    placeholder="المبلغ المدفوع"
                    name={'amountPaid'}
                    value={formik.values.amountPaid}
                    onChange={(name, amountPaid) =>
                      formik.setFieldValue(name, amountPaid)
                    }
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item>
              <Grid
                container
                direction={'row'}
                justifyContent={'space-between'}
              >
                <Button
                  variant="contained"
                  color="primary"
                  onClick={formik.handleSubmit}
                  sx={{
                    backgroundColor: 'text.secondary',
                    color: 'text.white',
                    borderRadius: '5px',
                    width: { xs: '145px', sm: '182px' },
                    height: { xs: '40px', sm: '44px' },
                    boxShadow: '0 0',
                    '&:hover': {
                      backgroundColor: 'text.secondary'
                    }
                  }}
                >
                  تآكيد الاشتراك
                </Button>

                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleClose}
                  sx={{
                    fontSize: { xs: '14px', sm: '16px' },
                    backgroundColor: 'background.primary',
                    color: 'text.secondary',
                    borderRadius: '5px',
                    width: { xs: '145px', sm: '178px' },
                    height: { xs: '40px', sm: '44px' },
                    boxShadow: '0 0',
                    border: '1px solid',
                    borderColor: 'text.secondary',
                    '&:hover': {
                      backgroundColor: 'background.primary',
                      color: 'text.secondary'
                    }
                  }}
                >
                  الغاء
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </Box>
  )
}
