import { Grid } from '@mui/material'
import { useEffect, useState, useCallback } from 'react'
import { useSelector } from 'react-redux'
import CancelButton from '../../components/CancelButton'
import ConfirmButton from '../../components/ConfirmButton'
import CustomTextField from '../../components/CustomTextField'
import LabelTypography from '../../components/LabelTypography'
import LoadingSpinner from '../../components/LoadingSpinner'
import { STATE_SUCCEEDED } from '../../constants'
import { selectCompanyProfile, selectGetProfileStatus } from './selectors'
import StaticAutocompleteSelect from '../../components/StaticAutocompleteSelect'
import { listGovernorates } from '../../api/governorate'
import toast from 'react-hot-toast'
import { updateCompany } from '../../api/profile'

export default function ManageCompanySection () {
  const companyProfile = useSelector(selectCompanyProfile)
  const getProfileStatus = useSelector(selectGetProfileStatus)
  const [data, setData] = useState()
  const [isLoading, setIsLoading] = useState(false)
  const [governorates, setGovernorates] = useState([])
  const [cities, setCities] = useState([])

  useEffect(() => {
    setData(companyProfile)
  }, [companyProfile])

  useEffect(async () => {
    setIsLoading(true)
    try {
      const listResonse = await listGovernorates()
      setGovernorates(listResonse.data.governorates)
    } catch (error) {
      toast.error('حدث خطآ ما برجاء المحاولة مره اخري')
    }
    setIsLoading(false)
  }, [])

  const onGovernorateSelect = useCallback(
    (e, gov) => {
      if (gov) {
        setData({
          ...data,
          governorate: gov,
          city: gov.city[0]
        })
        setCities(gov.city)
      }
    },
    [data]
  )

  const onCitySelect = useCallback(
    (e, city) => {
      setData({
        ...data,
        city: city
      })
    },
    [data]
  )

  const onChange = useCallback(
    (e) => {
      setData({
        ...data,
        [e.target.name]: e.target.value
      })
    },
    [data]
  )

  const onCancel = useCallback(() => setData(companyProfile), [companyProfile])

  const onSubmit = useCallback(async () => {
    setIsLoading(true)
    try {
      await updateCompany({
        companyName: data.name,
        address: data.address,
        governorateId: data.governorate?.id,
        cityId: data.city?.id
      })
      toast.success('تم تحديث البيانات بنجاح')
    } catch (error) {
      console.error(error)
      toast.error('حدث خطآ ما برجاء المحاولة مره اخري')
    }
    setIsLoading(false)
  }, [data])

  if (getProfileStatus !== STATE_SUCCEEDED || isLoading || !data) {
    return <LoadingSpinner />
  }

  return (
    <>
      <Grid item xs={12}>
        <LabelTypography
          sx={{
            color: 'text.primary',
            fontSize: '20px',
            fontWeight: 'bold'
          }}
        >
          ادارة حساب الشركة
        </LabelTypography>
      </Grid>
      <Grid item xs={6}>
        <CustomTextField
          required
          fullWidth
          label="اسم الشركة"
          name="name"
          onChange={onChange}
          value={data.name}
        />
      </Grid>

      <Grid item xs={6}>
        <CustomTextField
          required
          fullWidth
          label="العنوان"
          name="address"
          value={data.address}
          onChange={onChange}
        />
      </Grid>
      <Grid item xs={6}>
        <StaticAutocompleteSelect
          required
          fullWidth
          label="المحافظة"
          name="governorateId"
          options={governorates}
          onOptionSelect={onGovernorateSelect}
          selectedOption={data.governorate || {}}
        />
      </Grid>
      <Grid item xs={6}>
        <StaticAutocompleteSelect
          required
          fullWidth
          label="المدينة"
          name="cityId"
          options={cities}
          onOptionSelect={onCitySelect}
          selectedOption={data.city || {}}
        />
      </Grid>
      <Grid
        item
        xs={12}
        display={'flex'}
        justifyContent={'flex-end'}
        alignItems={'center'}
      >
        <ConfirmButton label={'حفظ'} onClick={onSubmit} />
        <CancelButton label={'إلغاء'} onClick={onCancel} />
      </Grid>
    </>
  )
}
