import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import {
  STATE_IDLE,
  STATE_SUCCEEDED,
  STATE_FAILED,
  STATE_LOADING
} from '../../constants'
import { listComapnyUsers } from '../../api/user'

const initialState = {
  users: [],
  selectedCompany: {},
  totalCount: 0,
  pageNumber: 1,
  pageSize: 50,
  q: '',
  status: STATE_IDLE,
  error: null
}

export const listUsersAsync = createAsyncThunk(
  'user/list',
  async ({ pageSize, pageNumber, q, companyId }) => {
    const listResult = await listComapnyUsers(companyId, {
      pageSize,
      pageNumber,
      q
    })
    return listResult.data
  }
)

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(listUsersAsync.pending, (state, action) => {
        state.status = STATE_LOADING
      })
      .addCase(listUsersAsync.fulfilled, (state, action) => {
        state.status = STATE_SUCCEEDED
        state.users = action.payload.users
        state.selectedCompany = action.payload.company
        state.totalCount = action.payload.totalCount
        state.pageNumber = action.payload.pageNumber
        state.pageSize = action.payload.pageSize
        state.q = action.payload.q
      })
      .addCase(listUsersAsync.rejected, (state, action) => {
        state.status = STATE_FAILED
        console.error(action.error)
        state.error = {
          message: action.error
        }
      })
  }
})

export default userSlice.reducer
