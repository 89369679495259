import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import {
  STATE_IDLE,
  STATE_SUCCEEDED,
  STATE_FAILED,
  STATE_LOADING
} from '../../constants'
import { listContacts } from '../../api/contact'

const initialState = {
  contacts: [],
  totalCount: 0,
  pageNumber: 1,
  pageSize: 100,
  q: '',
  status: STATE_IDLE,
  error: null
}

export const listContactsAsync = createAsyncThunk(
  'contact/list',
  async ({ pageSize, pageNumber, q }) => {
    const listResult = await listContacts({
      pageSize,
      pageNumber,
      q
    })
    return listResult.data
  }
)

export const contactSlice = createSlice({
  name: 'contact',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(listContactsAsync.pending, (state, action) => {
        state.status = STATE_LOADING
      })
      .addCase(listContactsAsync.fulfilled, (state, action) => {
        state.status = STATE_SUCCEEDED
        state.contacts = action.payload.contacts
        state.totalCount = action.payload.totalCount
        state.pageNumber = action.payload.pageNumber
        state.pageSize = action.payload.pageSize
        state.q = action.payload.q
      })
      .addCase(listContactsAsync.rejected, (state, action) => {
        state.status = STATE_FAILED
        console.error(action.error)
        state.error = {
          message: action.error
        }
      })
  }
})

export default contactSlice.reducer
