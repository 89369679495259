export const selectError = (state) => state.company.error
export const selectCompanies = (state) => state.company.companies
export const selectStatus = (state) => state.company.status
export const selectListCompaniesData = (state) => {
  return {
    totalCount: state.company.totalCount,
    pageNumber: state.company.pageNumber,
    pageSize: state.company.pageSize,
    q: state.company.q
  }
}
