import React from 'react'
import { Delete, Edit } from '@mui/icons-material'
import { Grid, IconButton, Switch } from '@mui/material'
import { Box } from '@mui/system'

import { COLORS } from '../../constants'

export default function CompanyUser ({ companyUser, showEditCompanyUserModal, showDeleteCompanyUserModal }) {
  return (
    <Grid
      item
      display={'flex'}
      justifyContent={'space-between'}
      xs={12}
      key={companyUser?.id}>
        <Grid item>
          {companyUser?.name}
        </Grid>
        <Grid item>
          <Box
            display={'flex'}
            alignItems={'center'}
            justifyContent={'space-between'}
            color={COLORS.primary}
          >
            <Switch
              checked={true}
              color="secondary"
            ></Switch>
            <IconButton
              sx={{
                color: 'text.secondary'
              }}
            >
              <Edit onClick={() => showEditCompanyUserModal(companyUser)} />
            </IconButton>
            <IconButton
              sx={{
                color: 'text.secondary'
              }}
            >
              <Delete onClick={() => showDeleteCompanyUserModal(companyUser)} />
            </IconButton>
          </Box>
        </Grid>
      </Grid>
  )
}
