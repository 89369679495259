import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import MuiTableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useParams } from 'react-router-dom'
import PagePagination from '../../components/Pagination'
import ResourceLoading from '../../components/ResourceLoading'
import CustomTableCell from '../../components/TabelCell'
import { STATE_SUCCEEDED } from '../../constants'
import { selectListUsersData, selectStatus, selectUsers } from './selectors'
import { listUsersAsync } from './slice'
import { styled } from '@mui/material/styles'
import CustomCell from '../../components/CustomCell'
import { getFormattedDate } from '../../utils/date'

const tableHead = [
  { id: 'sequence', label: 'م', minWidth: 50, borderTopLeftRadius: '5px' },
  { id: 'name', label: 'اسم المستخدم', minWidth: 100 },
  { id: 'email', label: 'البريد الالكتروني', minWidth: 50 },
  { id: 'phoneNumber', label: 'رقم التليفون', minWidth: 50 },
  { id: 'role', label: 'الوظيفة', minWidth: 50 },
  { id: 'isActive', label: 'نشط', minWidth: 50 },
  { id: 'createdAt', label: 'تاريخ الإنشاء', minWidth: 50 },
  {
    id: 'lastActiveAt',
    label: 'اخر استخدام',
    minWidth: 50,
    borderTopRightRadius: '5px'
  }
]
const TableCell = styled(MuiTableCell)(({ column, theme }) => ({
  ...(column.id === 'sequence' && {
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  })
}))

export default function ItemsTable () {
  const dispatch = useDispatch()
  const params = useParams()
  const companyId = params.companyID

  const users = useSelector(selectUsers)
  const getUsersStatus = useSelector(selectStatus)

  const location = useLocation()
  const query = new URLSearchParams(location.search)
  const page = parseInt(query.get('page') || '1', 10)
  const listData = useSelector(selectListUsersData)

  useEffect(() => {
    const pageSize = 25
    dispatch(
      listUsersAsync({
        companyId,
        pageSize,
        pageNumber: page,
        q: ''
      })
    )
  }, [page, companyId])

  if (getUsersStatus !== STATE_SUCCEEDED) {
    return <ResourceLoading />
  }

  return (
    <>
      <Paper
        sx={{
          marginTop: { xs: 1, sm: 2 },
          marginBottom: { xs: '35px', sm: 0 },
          boxShadow: '0 0'
        }}
      >
        <TableContainer>
          <Table
            stickyHeader
            aria-label="sticky table"
            sx={{
              boxShadow: '0px 1px 3px #00000029',
              borderTopLeftRadius: '5px',
              borderTopRightRadius: '5px',
              overflow: 'scroll',
              width: { xs: 'max-content', sm: '100%' }
            }}
          >
            <TableHead>
              <TableRow>
                {tableHead.map((column) => (
                  <TableCell
                    column={column}
                    key={column.id}
                    style={{
                      textAlign: 'center'
                    }}
                    sx={{
                      width: { md: column.minWidth },
                      bgcolor: 'rgb(242 135 5 / 0.5)',
                      color: 'text.black',
                      borderTopLeftRadius: column.borderTopLeftRadius,
                      borderTopRightRadius: column.borderTopRightRadius,
                      py: { xs: 1, md: '13px' },
                      px: { xs: 3, md: 3 },
                      fontSize: '14px'
                    }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody
              sx={{
                overflow: 'scroll'
              }}
            >
              {users.map((user, index) => {
                return (
                  <TableRow
                    hover
                    // sx={{
                    //   '&:hover': {
                    //     cursor: 'pointer',
                    //     textDecoration: 'underline'
                    //   }
                    // }}
                    key={index}
                    //   onClick={() => onClickSellOrder(sellOrder.id)}
                  >
                    <CustomCell>{index + 1}</CustomCell>
                    <CustomTableCell>{user.name || '-'}</CustomTableCell>
                    <CustomTableCell>{user.email || '-'}</CustomTableCell>
                    <CustomTableCell>{user.phoneNumber || '-'}</CustomTableCell>
                    <CustomTableCell>{user.role || '-'}</CustomTableCell>
                    <CustomTableCell>
                      {user.isActive ? 'Yes' : 'No'}
                    </CustomTableCell>
                    <CustomTableCell>
                      {user.createdAt ? getFormattedDate(user.createdAt) : '-'}
                    </CustomTableCell>
                    <CustomTableCell>
                      {user.lastActiveAt
                        ? getFormattedDate(user.lastActiveAt)
                        : '-'}
                    </CustomTableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>

      <PagePagination
        page={page}
        pageSize={listData.pageSize}
        totalCount={listData.totalCount}
      />
    </>
  )
}
