import React, { useState, useCallback, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { object, number, string, ref } from 'yup'
import toast from 'react-hot-toast'
import Backdrop from '@mui/material/Backdrop'
import Box from '@mui/material/Box'
import Modal from '@mui/material/Modal'
import Fade from '@mui/material/Fade'
import Grid from '@mui/material/Grid'

import StyledTextField from '../../components/StyledTextField'
import ResourceLoading from '../../components/ResourceLoading'
import NumberInputField from '../../components/NumberInputField'
import ConfirmButton from '../../components/ConfirmButton'
import CancelButton from '../../components/CancelButton'
import ModalLabelTypography from '../../components/ModalLabelTypography'
import ModalHeaderTypography from '../../components/ModalHeaderTypography'
import { COLORS } from '../../constants'
import { addNewCompanyUser, editCompanyUser } from '../../api/profile'
import { listCompanyUsersAsync } from './slice'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '40%',
  bgcolor: COLORS.background,
  borderRadius: '10px',
  boxShadow: 24,
  p: 4
}

const newCompanyUserSchema = object({
  phoneNumber: number().required().typeError('يجب ادخال رقم التلفون'),
  name: string().required('يجب ادخال اسم المستخدم'),
  confirmPass: string()
    .required('برجاء تأكيد كلمه المرور')
    .oneOf([ref('password')], 'يجب ان تكون كلمه المرور متطابقه'),
  password: string()
    .required('يجب ادخال كلمه المرور')
    .min(6, 'يجب ان لا تقل كلمه المرور عن سته ارقام'),
  email: string().email('ادخل ايميل صحيح').required('يجب ادخال الايميل')
})

const editCompanyUserSchema = object({
  phoneNumber: number().required().typeError('يجب ادخال رقم التلفون'),
  name: string().required('يجب ادخال اسم المستخدم'),
  email: string().email('ادخل ايميل صحيح').required('يجب ادخال الايميل')
})

export default function UpsertCompanyUser ({
  handleClose,
  open,
  isEditModal,
  companyUser
}) {
  const dispatch = useDispatch()
  const [companyUserData, setCompanyUserData] = useState('')

  useEffect(() => {
    isEditModal
      ? setCompanyUserData(companyUser)
      : setCompanyUserData({
        userName: '',
        email: '',
        password: '',
        confirmPass: '',
        phoneNumber: ''
      })
  }, [isEditModal, companyUser])

  const [isCreateCompanyUserLoading, setIsCreateCompanyUserLoading] =
    useState(false)

  const onInputChange = useCallback(
    (prop, value) => {
      setCompanyUserData({
        ...companyUserData,
        [prop]: value
      })
    },
    [companyUserData]
  )

  const onCancel = useCallback(() => {
    setCompanyUserData({
      userName: '',
      email: '',
      password: '',
      confirmPass: '',
      phoneNumber: ''
    })
    setIsCreateCompanyUserLoading(false)
    handleClose()
  })

  const onSubmit = useCallback(async () => {
    try {
      isEditModal
        ? editCompanyUserSchema.validateSync(companyUserData)
        : newCompanyUserSchema.validateSync(companyUserData)
    } catch (error) {
      const res = JSON.stringify(error)
      const msg = JSON.parse(res)
      return toast.error(msg.message)
    }
    setIsCreateCompanyUserLoading(true)
    try {
      isEditModal
        ? await editCompanyUser(companyUser.id, {
          userName: companyUserData.name,
          phoneNumber: companyUserData.phoneNumber,
          email: companyUserData.email
        })
        : await addNewCompanyUser({
          userName: companyUserData.name,
          phoneNumber: companyUserData.phoneNumber,
          email: companyUserData.email,
          password: companyUserData.password,
          passwordConfirmation: companyUserData.confirmPass
        })
      isEditModal
        ? toast.success('تم تعديل بيانات المستخدم بنجاح')
        : toast.success('تم إضافة مستخدم جديد بنجاح')
      dispatch(listCompanyUsersAsync())
      onCancel()
    } catch (error) {
      console.error(error)
      if (error.code) {
        toast.error('هذا الايميل موجود بالفعل')
      } else {
        toast.error('حدث خطآ ما برجاء المحاولة مرة اخري')
      }
      setIsCreateCompanyUserLoading(false)
    }
  }, [companyUserData])

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500
      }}
    >
      <Fade in={open}>
        <Box sx={style}>
          <Grid container direction={'column'} spacing={2}>
            {isCreateCompanyUserLoading ? (
              <ResourceLoading />
            ) : (
              <>
                <Grid item textAlign={'center'}>
                  <ModalHeaderTypography>إضافة عميل جديد</ModalHeaderTypography>
                </Grid>
                <Grid item>
                  <Grid
                    container
                    alignItems={'center'}
                    justifyContent={'space-between'}
                    padding={0}
                  >
                    <Grid item xs={2}>
                      <ModalLabelTypography>اسم المستخدم</ModalLabelTypography>
                    </Grid>
                    <Grid item xs={9}>
                      <StyledTextField
                        required
                        id="name"
                        placeholder=" اسم المستخدم "
                        name="name"
                        value={companyUserData.name}
                        onChange={(e) => onInputChange('name', e.target.value)}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <Grid
                    container
                    alignItems={'center'}
                    justifyContent={'space-between'}
                    padding={0}
                  >
                    <Grid item xs={2}>
                      <ModalLabelTypography>
                        البريد الالكتروني
                      </ModalLabelTypography>
                    </Grid>
                    <Grid item xs={9}>
                      <StyledTextField
                        required
                        id="email"
                        placeholder="البريد الالكتروني"
                        name="email"
                        value={companyUserData.email}
                        onChange={(e) => onInputChange('email', e.target.value)}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <Grid
                    container
                    alignItems={'center'}
                    justifyContent={'space-between'}
                    padding={0}
                  >
                    <Grid item xs={2}>
                      <ModalLabelTypography>رقم التليفون </ModalLabelTypography>
                    </Grid>
                    <Grid item xs={9}>
                      <NumberInputField
                        required
                        id="phoneNumber"
                        placeholder="رقم التليفون"
                        name={'phoneNumber'}
                        value={companyUserData.phoneNumber}
                        onChange={onInputChange}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                {!isEditModal && (
                  <>
                    <Grid item>
                      <Grid
                        container
                        alignItems={'center'}
                        justifyContent={'space-between'}
                        padding={0}
                      >
                        <Grid item xs={2}>
                          <ModalLabelTypography>
                            كلمه المرور{' '}
                          </ModalLabelTypography>
                        </Grid>
                        <Grid item xs={9}>
                          <StyledTextField
                            required
                            id="password"
                            type="password"
                            placeholder="كلمه المرور"
                            name="password"
                            value={companyUserData.password}
                            onChange={(e) =>
                              onInputChange('password', e.target.value)
                            }
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item>
                      <Grid
                        container
                        alignItems={'center'}
                        justifyContent={'space-between'}
                        padding={0}
                      >
                        <Grid item xs={2}>
                          <ModalLabelTypography>
                            تأكيد كلمه المرور{' '}
                          </ModalLabelTypography>
                        </Grid>
                        <Grid item xs={9}>
                          <StyledTextField
                            required
                            type="password"
                            id="confirmPass"
                            placeholder=" تأكيد كلمه المرور "
                            name="confirmPass"
                            value={companyUserData.confirmPass}
                            onChange={(e) =>
                              onInputChange('confirmPass', e.target.value)
                            }
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </>
                )}
                <Grid item style={{ textAlign: 'left' }}>
                  <ConfirmButton
                    label={isEditModal ? 'تعديل ' : 'إضافة'}
                    onClick={onSubmit}
                  />
                  <CancelButton label={'إلغاء'} onClick={onCancel} />
                </Grid>
              </>
            )}
          </Grid>
        </Box>
      </Fade>
    </Modal>
  )
}
