import {
  Box,
  Divider,
  Grid,
  TableBody,
  TableCell,
  Typography
} from '@mui/material'
import React, { useCallback, useEffect, useState } from 'react'
import { COLORS } from '../../constants'
import { makeStyles } from '@mui/styles'
import ArrowForward from '@mui/icons-material/ArrowForward'
import PeopleAltIcon from '@mui/icons-material/PeopleAlt'
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn'
import EventIcon from '@mui/icons-material/Event'
import RestoreIcon from '@mui/icons-material/Restore'
import PlaceIcon from '@mui/icons-material/Place'
import BusinessIcon from '@mui/icons-material/Business'
import GroupsIcon from '@mui/icons-material/Groups'
import EngineeringIcon from '@mui/icons-material/Engineering'
import AssignmentIcon from '@mui/icons-material/Assignment'
import FoundationIcon from '@mui/icons-material/Foundation'
import PeopleIcon from '@mui/icons-material/People'
import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp'
import { useNavigate, useParams } from 'react-router'
import { getCompany } from '../../api/company'
import { getFormattedDate, getDate } from '../../utils/date'
import CustomTableCell from '../../components/TabelCell'
import CreateSubscription from './CreateSubscription'
import ActionButton from '../../components/ActionButton'

const useStyles = makeStyles((theme) => ({
  backDiv: {
    marginTop: '10px',
    color: COLORS.secondary,
    '&:hover': {
      cursor: 'pointer',
      textDecoration: 'underline'
    },
    fontSize: '22px'
  }
}))

const tableHead = [
  {
    id: 'sequence',
    label: 'م',
    minWidth: 5,
    align: 'right',
    borderTopLeftRadius: '5px'
  },
  { id: 'user', label: 'اسم المستخدم', minWidth: 20, align: 'right' },
  { id: 'email', label: 'البريد الالكتروني', minWidth: 20, align: 'right' },
  { id: 'phoneNumber', label: 'رقم التليفون', minWidth: 20, align: 'right' },
  {
    id: 'date',
    label: 'تاريخ الإضافة',
    minWidth: 180,
    align: 'right',
    borderTopRightRadius: '5px'
  }
]

export default function Company() {
  const classes = useStyles()
  const navigate = useNavigate()
  const params = useParams()
  const companyId = params.companyID

  const [open, setOpen] = useState(false)
  const [dates, setDates] = useState([])

  const [isCreateSubscriptionOpen, setIsCreateSubscriptionOpen] =
    useState(false)

  const [companyData, setCompanyData] = useState('')

  useEffect(async () => {
    const res = await getCompany(companyId)
    setCompanyData(res.data.company)
  }, [companyId])

  useEffect(() => {
    setDates([
      companyData?.activeSubscriptionEndDate,
      companyData?.activeSubscriptionStartDate
    ])
  }, [companyData])

  console.log(companyData)

  const backToCompanies = useCallback(() => {
    return navigate('/')
  }, [])

  return (
    <>
      <Box
        sx={{
          pl: { xs: '16px', md: '24px', lg: '24px' },
          pr: { xs: '16px', md: '24px', lg: '24px' },
          mb: '40px',
          position: 'relative'
        }}
      >
        <Grid item xs={12} className={classes.backDiv} marginTop={3}>
          <Grid container>
            <Grid
              item
              xs={8}
              display="flex"
              flexDirection="row"
              alignItems={'center'}
              onClick={backToCompanies}
            >
              <ArrowForward
                style={{ color: COLORS.primary, marginLeft: '5px' }}
              />
              <Typography
                sx={{
                  fontSize: { xs: '16px', md: '18px' },
                  color: 'text.black',
                  fontWeight: { xs: 'bold', lg: 'normal' }
                }}
              >
                {companyData?.name || ' '}
              </Typography>
            </Grid>
            <Grid item xs={4} display={'flex'} justifyContent={'flex-end'}>
              <ActionButton onClick={() => setIsCreateSubscriptionOpen(true)}>
                تجديد الاشتراك
              </ActionButton>
              <Typography
                sx={{
                  display: { xs: 'block', lg: 'none' },
                  textDecoration: 'underline',
                  color: 'text.secondary',
                  fontSize: '14px'
                }}
                onClick={() => setIsCreateSubscriptionOpen(true)}
              >
                {' '}
                تجديد الاشتراك
              </Typography>

              <CreateSubscription
                open={isCreateSubscriptionOpen}
                handleClose={() => setIsCreateSubscriptionOpen(false)}
                company={companyData}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Grid
            container
            sx={{
              height: {
                xs: '262px',
                lg: '78px'
              },
              px: { lg: '32px' },
              py: { xs: '16px', lg: '24px' },
              boxShadow: '0px 1px 3px #00000029',
              borderRadius: '5px',
              backgroundColor: 'white',
              mt: '16px',
              textAlign: 'center',
              position: 'relative'
            }}
            marginTop={{ xs: 2, md: '16px' }}
            marginBottom={{ xs: 2, lg: 3 }}
          >
            <Grid xs={12} lg={4.8}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: { xs: 'column', lg: 'row' },
                  alignItems: 'center',
                  gap: { xs: '4px', lg: '16px' },
                  pb: { xs: '16px', lg: '0px' }
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '8px',
                    color: 'text.third',
                    '&:hover': {
                      cursor: 'pointer'
                    }
                  }}
                >
                  <Typography sx={{ fontSize: { xs: '14px', lg: '16px' } }}>
                    بداية - انتهاء الاشتراك
                  </Typography>
                </Box>
                <Typography
                  sx={{
                    fontSize: { xs: '14px', lg: '16px' },
                    color: 'text.black'
                  }}
                >
                  {dates &&
                    ` ${dates[1] || ''} ${(dates[0] && ' | ') || ''} ${
                      dates[0] || ''
                    }`}
                </Typography>
              </Box>
            </Grid>
            <Grid
              display={{ xs: 'block', lg: 'none' }}
              item={12}
              width={'100%'}
              height={'2px'}
              sx={{
                position: 'absolute',
                top: '25%',
                py: '16px'
              }}
            >
              <Divider
                variant={'middle'}
                sx={{ color: COLORS.third, width: '90%' }}
              />
            </Grid>

            <Grid
              xs={5.8}
              lg={2.2}
              sx={{
                display: { xs: 'flex', lg: 'block' },
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: { xs: 'column', lg: 'row' },
                  alignItems: 'center',
                  gap: { xs: '4px', lg: '16px' },
                  p: { xs: '16px', lg: '0px' }
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '8px'
                  }}
                >
                  <img src="/version-svgrepo-com.svg" />
                  <Typography
                    sx={{
                      fontSize: { xs: '14px', lg: '16px' },
                      color: 'text.third'
                    }}
                  >
                    النسخة
                  </Typography>
                </Box>
                <Typography
                  sx={{
                    fontSize: { xs: '14px', lg: '16px' },
                    color: 'text.black'
                  }}
                >
                  المتطورة
                </Typography>
              </Box>
            </Grid>
            <Grid
              item={0.2}
              sx={{
                display: { xs: 'flex', lg: 'none' },
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              <Divider
                orientation="vertical"
                variant={'middle'}
                sx={{
                  color: 'text.third',
                  height: '56px',
                  mt: '0px'
                }}
              />
            </Grid>

            <Grid
              xs={6}
              lg={2.2}
              sx={{
                display: { xs: 'flex', lg: 'block' },
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: { xs: 'column', lg: 'row' },
                  alignItems: 'center',
                  gap: { xs: '4px', lg: '16px' },
                  p: { xs: '16px', lg: '0px' }
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '8px',
                    color: 'text.third'
                  }}
                >
                  <PeopleAltIcon />
                  <Typography sx={{ fontSize: { xs: '14px', lg: '16px' } }}>
                    المستخدمين
                  </Typography>
                </Box>
                <Typography
                  sx={{
                    fontSize: { xs: '14px', lg: '16px' },
                    color: 'text.black'
                  }}
                >
                  {companyData?.activeSubscriptionNumberOfUsers}
                </Typography>
              </Box>
            </Grid>

            <Grid xs={12} lg={2.8}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: { xs: 'column', lg: 'row' },
                  alignItems: 'center',
                  gap: { xs: '4px', lg: '16px' },
                  pt: { xs: '16px', lg: '0px' }
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '8px',
                    color: 'text.third'
                  }}
                >
                  <MonetizationOnIcon />
                  <Typography sx={{ fontSize: { xs: '14px', lg: '16px' } }}>
                    إجمالي الاشتراك
                  </Typography>
                </Box>
                <Typography
                  sx={{
                    fontSize: { xs: '14px', lg: '16px' },
                    color: 'text.black'
                  }}
                >
                  {`${companyData.activeSubscriptionAmountPaid} ج.م`}
                </Typography>
              </Box>
            </Grid>
            <Grid
              display={{ xs: 'block', lg: 'none' }}
              item={12}
              width={'100%'}
              height={'2px'}
              sx={{
                position: 'absolute',
                top: '59%',
                py: '16px',
                px: 'auto'
              }}
            >
              <Divider
                variant={'middle'}
                sx={{ color: COLORS.third, width: '90%' }}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Grid
            container
            sx={{
              height: {
                xs: open ? '494px' : '62px',
                lg: '240px'
              },
              px: { xs: '16px', lg: '32px' },
              py: { xs: '16px', lg: '24px' },
              boxShadow: '0px 1px 3px #00000029',
              borderRadius: '5px',
              backgroundColor: 'white',
              mt: '16px',
              textAlign: 'center',
              position: 'relative'
            }}
          >
            <Grid item xs={12} marginBottom={{ xs: 1, lg: 3 }}>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center'
                }}
              >
                <Typography
                  sx={{
                    fontSize: { xs: '14px', lg: '16px' },
                    color: 'text.black'
                  }}
                >
                  تفاصيل الشركة
                </Typography>

                {!open && (
                  <ArrowDropDownIcon
                    sx={{
                      color: 'text.third',
                      display: { xs: 'block', lg: 'none' }
                    }}
                    onClick={() => {
                      setOpen(true)
                    }}
                  />
                )}
                {open && (
                  <ArrowDropUpIcon
                    sx={{
                      color: 'text.third',
                      display: { xs: 'block', lg: 'none' }
                    }}
                    onClick={() => {
                      setOpen(false)
                    }}
                  />
                )}
              </Box>
            </Grid>

            <Grid
              item
              xs={12}
              display={{ xs: open ? 'flex' : 'none', lg: 'flex' }}
            >
              <Grid container position={'relative'}>
                <Grid xs={5.8} lg={3.5} marginBottom={{ lg: 3 }}>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: { xs: 'column', lg: 'row' },
                      alignItems: { xs: 'flex-start', lg: 'center' },
                      gap: { xs: '4px', lg: '20px' },
                      p: { xs: '16px', lg: '0px' }
                    }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '8px',
                        color: 'text.third'
                      }}
                    >
                      <EventIcon />
                      <Typography sx={{ fontSize: { xs: '14px', lg: '16px' } }}>
                        تاريخ الانشاء{' '}
                      </Typography>
                    </Box>
                    <Typography
                      sx={{
                        fontSize: { xs: '14px', lg: '16px' },
                        color: 'text.black',
                        margin: { xs: 'auto', lg: '0px' }
                      }}
                    >
                      {getDate(companyData?.createdAt)}
                    </Typography>
                  </Box>
                </Grid>
                <Grid
                  item={0.2}
                  sx={{
                    display: { xs: 'flex', lg: 'none' },
                    justifyContent: 'center',
                    alignItems: 'center',
                    position: 'absolute',
                    right: '50%'
                  }}
                >
                  <Divider
                    orientation="vertical"
                    variant={'middle'}
                    sx={{
                      color: 'text.third',
                      height: '410px',
                      mt: '0px'
                    }}
                  />
                </Grid>
                <Grid
                  display={{ xs: 'block', lg: 'none' }}
                  item={12}
                  width={'100%'}
                  height={'2px'}
                  sx={{
                    position: 'absolute',
                    top: '15%',
                    py: '16px'
                  }}
                >
                  <Divider
                    variant={'middle'}
                    sx={{ color: COLORS.third, width: '90%' }}
                  />
                </Grid>

                <Grid xs={6} lg={3.25} marginBottom={{ lg: 3 }}>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: { xs: 'column', lg: 'row' },
                      alignItems: 'center',
                      gap: { xs: '4px', lg: '24px' },
                      p: { xs: '16px', lg: '0px' }
                    }}
                  >
                    <Box
                      sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}
                    >
                      <RestoreIcon sx={{ color: 'text.third' }} />
                      <Typography
                        sx={{
                          fontSize: { xs: '14px', lg: '16px' },
                          color: 'text.third'
                        }}
                      >
                        آخر استخدام
                      </Typography>
                    </Box>
                    <Typography
                      sx={{
                        fontSize: { xs: '14px', lg: '16px' },
                        color: 'text.black'
                      }}
                    >
                      {getFormattedDate(companyData?.lastActiveAt)}
                    </Typography>
                  </Box>
                </Grid>

                <Grid xs={6} lg={2.75} marginBottom={{ lg: 3 }}>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: { xs: 'column', lg: 'row' },
                      alignItems: { xs: 'flex-start', lg: 'center' },
                      gap: { xs: '4px', lg: '16px' },
                      p: { xs: '16px', lg: '0px' }
                    }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '8px',
                        color: 'text.third'
                      }}
                    >
                      <PlaceIcon />
                      <Typography sx={{ fontSize: { xs: '14px', lg: '16px' } }}>
                        المحافظة
                      </Typography>
                    </Box>
                    <Typography
                      sx={{
                        fontSize: { xs: '14px', lg: '16px' },
                        color: 'text.black',
                        margin: { xs: 'auto', lg: '0px' }
                      }}
                    >
                      {companyData?.governorate?.name}
                    </Typography>
                  </Box>
                </Grid>
                <Grid
                  display={{ xs: 'block', lg: 'none' }}
                  item={12}
                  width={'100%'}
                  height={'2px'}
                  sx={{
                    position: 'absolute',
                    top: '35%',
                    py: '16px'
                  }}
                >
                  <Divider
                    variant={'middle'}
                    sx={{ color: COLORS.third, width: '90%' }}
                  />
                </Grid>

                <Grid xs={6} lg={2.5} marginBottom={{ lg: 3 }}>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: { xs: 'column', lg: 'row' },
                      alignItems: 'center',
                      gap: { xs: '4px', lg: '16px' },
                      p: { xs: '16px', lg: '0px' }
                    }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '8px',
                        color: 'text.third'
                      }}
                    >
                      <BusinessIcon />
                      <Typography sx={{ fontSize: { xs: '14px', lg: '16px' } }}>
                        المدينة{' '}
                      </Typography>
                    </Box>
                    <Typography
                      sx={{
                        fontSize: { xs: '14px', lg: '16px' },
                        color: 'text.black',
                        margin: { xs: 'auto', lg: '0px' }
                      }}
                    >
                      {companyData?.city?.name}
                    </Typography>
                  </Box>
                </Grid>

                <Grid xs={6} lg={3.5} marginBottom={{ lg: 3 }}>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: { xs: 'column', lg: 'row' },
                      alignItems: { xs: 'flex-start', lg: 'center' },
                      gap: { xs: '4px', lg: '40px' },
                      p: { xs: '16px', lg: '0px' }
                    }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '8px',
                        color: 'text.third'
                      }}
                    >
                      <FoundationIcon />
                      <Typography sx={{ fontSize: { xs: '14px', lg: '16px' } }}>
                        المشروعات{' '}
                      </Typography>
                    </Box>
                    <Typography
                      sx={{
                        fontSize: { xs: '14px', lg: '16px' },
                        color: 'text.black',
                        margin: { xs: 'auto', lg: '0px' }
                      }}
                    >
                      {companyData?.projectsCount}
                    </Typography>
                  </Box>
                </Grid>
                <Grid
                  display={{ xs: 'block', lg: 'none' }}
                  item={12}
                  width={'100%'}
                  height={'2px'}
                  sx={{
                    position: 'absolute',
                    top: '55%',
                    py: '16px'
                  }}
                >
                  <Divider
                    variant={'middle'}
                    sx={{ color: COLORS.third, width: '90%' }}
                  />
                </Grid>

                <Grid xs={6} lg={3.25} marginBottom={{ lg: 3 }}>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: { xs: 'column', lg: 'row' },
                      alignItems: 'center',
                      gap: { xs: '4px', lg: '40px' },
                      p: { xs: '16px', lg: '0px' }
                    }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '8px',
                        color: 'text.third'
                      }}
                    >
                      <GroupsIcon />
                      <Typography sx={{ fontSize: { xs: '14px', lg: '16px' } }}>
                        العملاء
                      </Typography>
                    </Box>
                    <Typography
                      sx={{
                        fontSize: { xs: '14px', lg: '16px' },
                        color: 'text.black',
                        margin: { xs: 'auto', lg: '0px' }
                      }}
                    >
                      {companyData?.clientsCount}
                    </Typography>
                  </Box>
                </Grid>

                <Grid xs={6} lg={2.75} marginBottom={{ lg: 3 }}>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: { xs: 'column', lg: 'row' },
                      alignItems: { xs: 'flex-start', lg: 'center' },
                      gap: { xs: '4px', lg: '40px' },
                      p: { xs: '16px', lg: '0px' }
                    }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '8px',
                        color: 'text.third'
                      }}
                    >
                      <AssignmentIcon />
                      <Typography sx={{ fontSize: { xs: '14px', lg: '16px' } }}>
                        البنود
                      </Typography>
                    </Box>
                    <Typography
                      sx={{
                        fontSize: { xs: '14px', lg: '16px' },
                        color: 'text.black',
                        margin: { xs: 'auto', lg: '0px' }
                      }}
                    >
                      {companyData?.servicesCount}
                    </Typography>
                  </Box>
                </Grid>
                <Grid
                  display={{ xs: 'block', lg: 'none' }}
                  item={12}
                  width={'100%'}
                  height={'2px'}
                  sx={{
                    position: 'absolute',
                    top: '75%',
                    py: '16px'
                  }}
                >
                  <Divider
                    variant={'middle'}
                    sx={{ color: COLORS.third, width: '90%' }}
                  />
                </Grid>

                <Grid xs={6} lg={2.5} marginBottom={{ lg: 3 }}>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      flexDirection: { xs: 'column', lg: 'row' },
                      gap: { xs: '4px', lg: '40px' },
                      p: { xs: '16px', lg: '0px' }
                    }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '8px',
                        color: 'text.third'
                      }}
                    >
                      <EngineeringIcon />
                      <Typography sx={{ fontSize: { xs: '14px', lg: '16px' } }}>
                        الخامات{' '}
                      </Typography>
                    </Box>
                    <Typography
                      sx={{
                        fontSize: { xs: '14px', lg: '16px' },
                        color: 'text.black',
                        margin: { xs: 'auto', lg: '0px' }
                      }}
                    >
                      {companyData?.itemsCount}
                    </Typography>
                  </Box>
                </Grid>

                <Grid xs={6} lg={3.5}>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: { xs: 'column', lg: 'row' },
                      alignItems: { xs: 'flex-start', lg: 'center' },
                      gap: { xs: '4px', lg: '40px' },
                      p: { xs: '16px', lg: '0px' },
                      pb: '0px'
                    }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '8px',
                        color: 'text.third'
                      }}
                    >
                      <EngineeringIcon />
                      <Typography sx={{ fontSize: { xs: '14px', lg: '16px' } }}>
                        المقاولين{' '}
                      </Typography>
                    </Box>
                    <Typography
                      sx={{
                        fontSize: { xs: '14px', lg: '16px' },
                        color: 'text.black',
                        margin: { xs: 'auto', lg: '0px' }
                      }}
                    >
                      {companyData?.contractorsCount}
                    </Typography>
                  </Box>
                </Grid>

                <Grid xs={6} lg={3}>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: { xs: 'column', lg: 'row' },
                      alignItems: 'center',
                      gap: { xs: '4px', lg: '40px' },
                      p: { xs: '16px', lg: '0px' },
                      pb: '0px'
                    }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '8px',
                        color: 'text.third'
                      }}
                    >
                      <PeopleIcon />
                      <Typography sx={{ fontSize: { xs: '14px', lg: '16px' } }}>
                        الموردين
                      </Typography>
                    </Box>
                    <Typography
                      sx={{
                        fontSize: { xs: '14px', lg: '16px' },
                        color: 'text.black',
                        margin: { xs: 'auto', lg: '0px' }
                      }}
                    >
                      {companyData?.suppliersCount}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Paper
            sx={{
              marginTop: { xs: 3, sm: 2 },
              boxShadow: '0 0'
            }}
          >
            <TableContainer>
              <Table
                stickyHeader
                aria-label="sticky table"
                sx={{
                  boxShadow: '0px 1px 3px #00000029',
                  borderTopLeftRadius: '5px',
                  borderTopRightRadius: '5px',
                  overflow: 'scroll',
                  width: { xs: 'max-content', sm: '100%' }
                }}
              >
                <TableHead>
                  <TableRow>
                    {tableHead.map((column) => (
                      <TableCell
                        column={column}
                        key={column.id}
                        style={{
                          textAlign: 'right'
                        }}
                        sx={{
                          width: { md: column.minWidth },
                          bgcolor: 'rgb(242 135 5 / 0.5)',
                          color: 'text.black',
                          borderTopLeftRadius: column.borderTopLeftRadius,
                          borderTopRightRadius: column.borderTopRightRadius,
                          py: { xs: 1, md: '15px' },
                          px: { xs: '20px', md: '24px' },
                          fontSize: '14px',
                          fontWeight: '600'
                        }}
                      >
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>

                <TableBody
                  sx={{
                    overflow: 'scroll'
                  }}
                >
                  {companyData?.user?.map((user, index) => {
                    return (
                      <TableRow hover key={user.id}>
                        <CustomTableCell>{index + 1}</CustomTableCell>
                        <CustomTableCell>{user?.name}</CustomTableCell>
                        <CustomTableCell>{user?.email}</CustomTableCell>
                        <CustomTableCell>{user?.phoneNumber}</CustomTableCell>
                        <CustomTableCell>
                          {getFormattedDate(user?.createdAt)}
                        </CustomTableCell>
                      </TableRow>
                    )
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Grid>
      </Box>
    </>
  )
}
