export const STATE_IDLE = 'idle'
export const STATE_LOADING = 'loading'
export const STATE_SUCCEEDED = 'succeeded'
export const STATE_FAILED = 'failed'

export const COLORS = {
  primary: '#F28705', // orange
  secondary: '#195C92',
  third: '#9A9A9A',
  primaryBackground: '#EEEEEE',
  background: '#FFFFFF', // white background
  secondaryBackground: '#F9F9F9',
  white: '#EEF2F6',
  buttonColor: '#121826',
  buttonBackground: '#768AE4',
  menuBackground: '#00000029',
  export: '#4caf50', // green
  black: '#000000'
}
