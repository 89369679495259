import Typography from '@mui/material/Typography'

const ModalHeaderTypography = ({ children, ...props }) => (
  <Typography
    component="h1"
    variant="h5"
    sx={{
      fontSize: { xs: '16px', sm: '20px', md: '22px' },
      color: 'text.secondary',
      fontWeight: 'bold'
    }}
    {...props}
  >
    {children}
  </Typography>
)

export default ModalHeaderTypography
