import React, { useCallback, useMemo } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { makeStyles } from '@mui/styles'

import MuiDrawer from '@mui/material/Drawer'
import MuiList from '@mui/material/List'

import ListItem from '@mui/material/ListItem'

import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import { COLORS } from '../constants'
import BusinessIcon from '@mui/icons-material/Business'
import { styled } from '@mui/material/styles'
import IconButton from '@mui/material/IconButton'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import ContactSupportIcon from '@mui/icons-material/ContactSupport'
import { Box, Tooltip } from '@mui/material'

const drawerWidth = 200

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen
  }),
  overflowX: 'hidden'
})

const closedMixin = (theme) => ({
  overflowX: 'hidden',
  width: '2px'
})

const useStyles = makeStyles((theme) => ({
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingRight: '30px',
    [theme.breakpoints.down('md')]: {
      paddingRight: '20px'
    },
    paddingTop: '10px',
    gap: '20px',
    // necessary for content to be below app bar
    ...theme.mixins.toolbar
  }
}))

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open'
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  background: COLORS.background,
  borderRight: 0,
  // paddingLeft: 1,
  '& .muirtl-yawxep-MuiPaper-root-MuiDrawer-paper': {
    color: 'white'
  },
  ...(open && {
    ...openedMixin(theme),
    [theme.breakpoints.up('lg')]: {
      width: 200
    },
    [theme.breakpoints.down('md')]: {
      width: 200,
      position: 'absolute',
      top: 0,
      right: 0
    },
    [theme.breakpoints.down('sm')]: {
      width: 190,
      position: 'absolute',
      top: 0,
      right: 0
    },
    '& .MuiDrawer-paper ': {
      ...openedMixin(theme),
      [theme.breakpoints.up('lg')]: {
        width: 200
      },
      [theme.breakpoints.down('md')]: {
        width: 200
      },
      [theme.breakpoints.down('sm')]: {
        width: 190
      }
    }
  }),
  ...(!open && {
    ...closedMixin(theme),
    '&': {
      [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(8)} + 1px)`
      },
      [theme.breakpoints.up('md')]: {
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.enteringScreen
        })
      }
    },
    '& .MuiDrawer-paper': {
      ...closedMixin(theme),
      [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(8)} + 1px)`
      }
    }
  })
}))

const List = styled(MuiList)(({ theme }) => ({
  '& .MuiButtonBase-root': {
    [theme.breakpoints.down('md')]: {
      paddingRight: '8px',
      paddingLeft: '8px'
    }
  },
  '& .MuiListItemIcon-root': {
    [theme.breakpoints.down('md')]: {
      minWidth: '40px'
    }
  },
  '& .MuiTypography-root': {
    [theme.breakpoints.down('md')]: {
      fontSize: '14px'
    }
  }
}))

const CustomListItemIcon = ({ children }) => (
  <ListItemIcon style={{ color: 'inherit' }}>{children}</ListItemIcon>
)

const styles = {
  sideBarListItem: {
    color: 'text.third',
    '&:hover': {
      color: 'text.secondary',
      backgroundColor: 'background.primary'
    }
  },
  selectedSideBarListItem: {
    color: 'text.secondary'
  },
  drawer: {
    flexShrink: 0,
    whiteSpace: 'nowrap',
    background: COLORS.background,
    borderRight: 0
    // paddingLeft: 1
  }
}
export default function Sidebar({ open, handleDrawerClose }) {
  const classes = useStyles()
  const navigate = useNavigate()
  const location = useLocation()
  const onClickMenuItem = useCallback(
    (path) => {
      navigate(path)
      handleDrawerClose()
    },
    [navigate]
  )

  const path = useMemo(() => location.pathname.slice(1), [location])

  return (
    <Drawer
      variant="permanent"
      open={open}
      PaperProps={{
        sx: styles.drawer
      }}
    >
      <div className={classes.toolbar}>
        <Box>
          <img src="/civilia-logo.svg" width="100px" alt="Civilia" />
        </Box>
        <IconButton
          onClick={handleDrawerClose}
          sx={{
            color: COLORS.primary,
            display: 'flex',
            paddingTop: '10px',
            paddingRight: { xs: '12px', md: '8px' },
            minWidth: '35px',
            height: '35px'
          }}
        >
          <ChevronRightIcon />
        </IconButton>
      </div>

      <List>
        <Tooltip title="الشركات" placement="left" arrow>
          <ListItem
            button
            onClick={() => onClickMenuItem('/')}
            sx={
              path === ''
                ? styles.selectedSideBarListItem
                : styles.sideBarListItem
            }
          >
            <CustomListItemIcon>
              <BusinessIcon />
            </CustomListItemIcon>
            <ListItemText sx={{ opacity: open ? 1 : 0 }}>
              <span>الشركات</span>
            </ListItemText>
          </ListItem>
        </Tooltip>
        <Tooltip title="تواصل معنا" placement="left" arrow>
          <ListItem
            button
            onClick={() => onClickMenuItem('/contact-us')}
            sx={
              path === 'contact-us'
                ? styles.selectedSideBarListItem
                : styles.sideBarListItem
            }
          >
            <CustomListItemIcon>
              <ContactSupportIcon />
            </CustomListItemIcon>
            <ListItemText sx={{ opacity: open ? 1 : 0 }}>
              <span>رسائل تواصل معنا</span>
            </ListItemText>
          </ListItem>
        </Tooltip>
      </List>
    </Drawer>
  )
}
