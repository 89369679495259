import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import {
  STATE_IDLE,
  STATE_SUCCEEDED,
  STATE_FAILED,
  STATE_LOADING
} from '../../constants'
import { getCompanyUsers, getProfile } from '../../api/profile'

const initialState = {
  userProfile: {},
  getProfileStatus: STATE_IDLE,
  getUserProfileError: {},
  companyProfile: {},
  getCompanyProfileStatus: STATE_IDLE,
  listCompanyUsers: [],
  getCompanyUsersError: {},
  getCompanyUsersStatus: STATE_IDLE
}

export const getProfileAsync = createAsyncThunk(
  'profile/user-profile',
  async () => {
    const getUserProfileResult = await getProfile()
    return getUserProfileResult.data?.user
  }
)

export const listCompanyUsersAsync = createAsyncThunk(
  'profile/company-users',
  async () => {
    const getCompanyUsersResult = await getCompanyUsers()
    return getCompanyUsersResult.data?.users
  }
)

export const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getProfileAsync.pending, (state, action) => {
        state.getProfileStatus = STATE_LOADING
      })
      .addCase(getProfileAsync.fulfilled, (state, action) => {
        state.getProfileStatus = STATE_SUCCEEDED
        state.userProfile = action.payload
        state.companyProfile = action.payload.company
      })
      .addCase(getProfileAsync.rejected, (state, action) => {
        state.getProfileStatus = STATE_FAILED
        console.error(action.error)
        state.getUserProfileError = {
          code: action.error.code,
          message: action.error.message
        }
      })
      .addCase(listCompanyUsersAsync.pending, (state, action) => {
        state.getCompanyUsersStatus = STATE_LOADING
      })
      .addCase(listCompanyUsersAsync.fulfilled, (state, action) => {
        state.getCompanyUsersStatus = STATE_SUCCEEDED
        state.listCompanyUsers = action.payload
      })
      .addCase(listCompanyUsersAsync.rejected, (state, action) => {
        state.getCompanyUsersStatus = STATE_FAILED
        console.error(action.error)
        state.getCompanyUsersError = {
          code: action.error.code,
          message: action.error.message
        }
      })
  }
})

export default profileSlice.reducer
