import Button from '@mui/material/Button'

export default function ConfirmButton ({ label, onClick }) {
  return (
    <Button
      variant="contained"
      color="primary"
      onClick={onClick}
      style={{
        marginLeft: '20px'
      }}
      sx={{
        backgroundColor: 'text.secondary',
        borderRadius: '5px',
        width: { xs: '80px', sm: '100px' },
        height: { xs: '35px', sm: '50px' },
        boxShadow: '0 0',
        '&:hover': {
          backgroundColor: 'text.secondary'
        }
      }}
    >
      {label}
    </Button>
  )
}
