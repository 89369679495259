import Button from '@mui/material/Button'

export default function CancelButton ({ label, onClick }) {
  return (
    <Button
      variant="contained"
      color="primary"
      onClick={onClick}
      sx={{
        fontSize: { xs: '14px', sm: '16px' },
        backgroundColor: 'background.primary',
        color: 'text.secondary',
        borderRadius: '5px',
        width: { xs: '80px', sm: '100px' },
        height: { xs: '35px', sm: '50px' },
        boxShadow: '0 0',
        border: '1px solid',
        borderColor: 'text.secondary',
        '&:hover': {
          backgroundColor: 'background.primary',
          color: 'text.secondary'
        }
      }}
    >
      {label}
    </Button>
  )
}
