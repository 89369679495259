import React from 'react'
import TableCell from '@mui/material/TableCell'
export default function HoverCell (props) {
  const { children, ...rest } = props
  return (
    <TableCell
      style={{
        textAlign: 'center'
      }}
      sx={{
        '&:hover': {
          cursor: 'pointer',
          color: 'text.secondary',
          textDecoration: 'underline'
        },
        bgcolor: 'background.primary',
        color: 'text.black',
        borderBottom: '1px solid #D8D8D8',
        py: { xs: 1, md: '13px' },
        px: { xs: 3, md: 3 },
        fontSize: '14px'
      }}
      {...rest}
    >
      {children}
    </TableCell>
  )
}
