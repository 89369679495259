import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

import PagePagination from '../../components/Pagination'
import ResourceLoading from '../../components/ResourceLoading'
import CustomTableCell from '../../components/TabelCell'
import { getDate } from '../../utils/date'
import { listContactUsMessages } from '../../api/contact-us'

const tableHead = [
  {
    id: 'sequence',
    label: 'م',
    minWidth: 5,
    align: 'right',
    borderTopLeftRadius: '5px'
  },
  { id: 'name', label: 'الاسم', minWidth: '50px', align: 'right' },
  {
    id: 'email',
    label: 'الايميل / رقم التليفون',
    minWidth: '115px',
    align: 'right'
  },
  {
    id: 'message',
    label: 'الرسالة',
    minWidth: '120px',
    align: 'right'
  },
  { id: 'createdAt', label: 'تاريخ الارسال', minWidth: '20px', align: 'right' }
]

export default function ItemsTable () {
  const location = useLocation()
  const query = new URLSearchParams(location.search)
  const page = parseInt(query.get('page') || '1', 10)

  const [messages, setMessages] = useState([])
  const [totalCount, setTotalCount] = useState(0)
  const [loading, setLoading] = useState(true)

  useEffect(async() => {
    const pageSize = 100
    setLoading(true)
    try {
      const listMessagesResult = await listContactUsMessages({ page, pageSize })
      setMessages(listMessagesResult.data.contacts)
      setTotalCount(listMessagesResult.data.totalCount)
    } catch (error) {
      console.log(error)
    }
    setLoading(false)
  }, [page])

  if (loading) {
    return <ResourceLoading />
  }

  return (
    <>
      <Paper
        sx={{
          marginTop: { xs: 1, sm: 0 },
          boxShadow: '0 0'
        }}
      >
        <TableContainer>
          <Table
            stickyHeader
            aria-label="sticky table"
            sx={{
              boxShadow: '0px 1px 3px #00000029',
              borderTopLeftRadius: '5px',
              borderTopRightRadius: '5px',
              overflow: 'scroll',
              width: { xs: 'max-content', sm: '100%' }
            }}
          >
            <TableHead>
              <TableRow>
                {tableHead.map((column) => (
                  <TableCell
                    column={column}
                    key={column.id}
                    style={{
                      textAlign: 'start'
                    }}
                    sx={{
                      width: { md: column.minWidth },
                      bgcolor: 'rgb(242 135 5 / 0.5)',
                      color: 'text.black',
                      borderTopLeftRadius: column.borderTopLeftRadius,
                      borderTopRightRadius: column.borderTopRightRadius,
                      py: { xs: 1, md: '13px' },
                      px: { xs: 3, md: 3 },
                      fontSize: '14px'
                    }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody
              sx={{
                overflow: 'scroll'
              }}
            >
              {messages.map((message, index) => {
                return (
                  <TableRow key={index}>
                    <CustomTableCell>{index + 1}</CustomTableCell>
                    <CustomTableCell
                    >
                      {message.name}
                    </CustomTableCell>
                    <CustomTableCell>
                      {message.email}
                    </CustomTableCell>
                    <CustomTableCell>
                      {message.message}
                    </CustomTableCell>
                    <CustomTableCell>{getDate(message.createdAt)}</CustomTableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>

      <PagePagination
        page={page}
        pageSize={100}
        totalCount={totalCount}
      />
    </>
  )
}
