// USE THIS
import MuiTextField from '@mui/material/TextField'
import { COLORS } from '../constants'
import { styled } from '@mui/material/styles'

const TextField = styled(MuiTextField)(({ theme }) => ({
  height: '52px',
  [theme.breakpoints.down('md')]: {
    height: '40px',
    padding: '0 10px',
    paddingTop: '5px'
  },
  '& .MuiInput-input': {
    '&::placeholder': {
      [theme.breakpoints.up('sm')]: {
        opacity: '0'
      }
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '14px'
    }
  }
}))
export default function StyledTextField (props) {
  return (
    <TextField
      fullWidth
      InputProps={{
        disableUnderline: true
      }}
      variant="standard"
      sx={{
        borderRadius: '5px',
        backgroundColor: COLORS.primaryBackground,
        padding: '10px',
        color: 'text.black',
        '& .MuiInputBase-input': {
          color: 'text.black',
          fontSize: '14px'
        }
      }}
      {...props}
    />
  )
}
