import { Autocomplete } from '@mui/material'
import CustomTextField from './CustomTextField'

export default function StaticAutocompleteSelect ({
  label,
  options,
  onOptionSelect,
  selectedOption
}) {
  return (
    <Autocomplete
      id="static-select"
      options={options}
      autoComplete={true}
      getOptionLabel={(option) => option.name || ''}
      onChange={onOptionSelect}
      value={selectedOption}
      renderInput={(params) => (
        <CustomTextField
          {...params}
          label={label}
          inputProps={{
            ...params.inputProps,
            autoComplete: 'new-password' // disable autocomplete and autofill
          }}
          InputProps={{
            ...params.InputProps,
            disableUnderline: true
          }}
        />
      )}
    />
  )
}
