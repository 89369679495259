import { styled } from '@mui/material/styles'
import TextField from '@mui/material/TextField'
import { COLORS } from '../constants'

const StyledTextField = styled(TextField)({
  '& .MuiFilledInput-root': {
    borderRadius: '10px',
    height: '50px',
    backgroundColor: COLORS.primaryBackground
  }
})

const CustomTextField = (props) => (
  <StyledTextField
    variant="filled"
    margin="normal"
    InputProps={{
      disableUnderline: true
    }}
    {...props}
  />
)
export default CustomTextField
