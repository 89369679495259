import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Button from '@mui/material/Button'
import CssBaseline from '@mui/material/CssBaseline'
import Link from '@mui/material/Link'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import { makeStyles } from '@mui/styles'
import Container from '@mui/material/Container'
import { styled } from '@mui/material/styles'
import { loginAsync } from './slice'
import { selectError, selectStatus } from './selectors'
import { STATE_LOADING, COLORS } from '../../constants'
import { ReactComponent as ReactLogo } from './home.svg'
import CustomTextField from '../../components/CustomTextField'
import CustomTypographyH1 from '../../components/CustomH1'

const useStyles = makeStyles((theme) => ({
  submit: {
    margin: theme.spacing(3, 0, 2)
  }
}))

const ImgBox = styled(Box)(({ theme }) => ({
  '& .image': {
    [theme.breakpoints.up('md')]: {
      width: '350px'
    },
    [theme.breakpoints.up('lg')]: {
      width: '550px',
      height: '600'
    }
  },
  [theme.breakpoints.down('md')]: {
    display: 'none'
  }
}))

const Content = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    position: 'absolute',
    top: '50%',
    transform: 'translateY(50%)'
  }
}))

export default function Login () {
  const classes = useStyles()
  const dispatch = useDispatch()
  const loginError = useSelector(selectError)
  const loginStatus = useSelector(selectStatus)

  const [email, setEmail] = useState('')
  const [emailError, setEmailError] = useState('')

  const [password, setPassword] = useState('')

  const onEmailChange = (e) => {
    const value = e.target.value
    setEmailError('')
    setEmail(value)
  }

  const onPasswordChange = (e) => {
    const value = e.target.value
    setPassword(value)
  }

  const submit = () => {
    if (loginStatus === STATE_LOADING) {
      return
    }
    return dispatch(loginAsync({ email, password }))
  }

  return (
    <Container component="main" >
      <CssBaseline />
      <Grid container direction={'row'} position={'relative'}>
          <Grid item xs={12} md={7} lg={6} display={'flex'} alignItems={'center'}>
            <Content container direction={'column'} alignItems={'center'} >
            <Grid item>
              <CustomTypographyH1
                fontSize={{ xs: '22px !important', sm: '25px !important', md: '30px !important' }}
              >تسجيل الدخول</CustomTypographyH1>
            </Grid>
            <Grid item width={{ xs: '335px', sm: '60%' }} >
              <CustomTextField
                required
                fullWidth
                id="email"
                label="البريد الالكتروني"
                name="email"
                autoComplete="email"
                autoFocus
                onChange={onEmailChange}
                value={email}
                error={!!emailError}
                helperText={emailError}
              />
            </Grid>
            <Grid item width={{ xs: '335px', sm: '60%' }}>
              <CustomTextField
                required
                fullWidth
                name="password"
                label="كلمة المرور"
                type="password"
                id="password"
                autoComplete="current-password"
                value={password}
                onChange={onPasswordChange}
                error={!!loginError}
                helperText={loginError ? loginError.message : ''}
              />
            </Grid>
            <Grid
              item
              width={{ xs: '335px', sm: '60%' }}
              marginBottom={{ xs: '24px', md: '20px' }}
              style={{
                textAlign: 'left',
                marginTop: 0
              }}
            >
              <Link
                href="#"
                variant="body2"
                style={{
                  color: COLORS.primary
                }}
              >
                هل نسيت كلمة المرور؟
              </Link>
            </Grid>
            <Grid item width={{ xs: '335px', sm: '60%' }}>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                onClick={submit}
                style={{
                  backgroundColor: COLORS.primary,
                  borderRadius: '10px'
                }}
              >
                تسجيل الدخول
              </Button>
            </Grid>
            <Grid
              item
              width={{ xs: '335px', sm: '60%' }}
              textAlign={'center'}
              style={{
                color: COLORS.secondary
              }}
              marginTop={{ xs: '24px', md: '10px' }}
            >
              ليس لديك حساب؟{' '}
              <Link
                href="/register"
                variant="body2"
                style={{
                  color: COLORS.primary
                }}
              >
                انشاء حساب جديد
              </Link>
            </Grid>
            </Content>
          </Grid>

        <ImgBox>
          <Grid item md={4} lg={6} >
            <ReactLogo width={'550'} height={'600'} className='image'/>
          </Grid>
      </ImgBox>

      </Grid>
    </Container>
  )
}
