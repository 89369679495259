import Typography from '@mui/material/Typography'

export default function LabelTypography ({ children, ...props }) {
  return (
    <Typography
      sx={{
        fontSize: { xs: '14px', md: '18px' },
        fontWeight: '400',
        color: 'text.primary'
      }}
      {...props}
    >
      {children}
    </Typography>
  )
}
