import MuiTextField from '@mui/material/TextField'
import InputAdornment from '@mui/material/InputAdornment'
import SearchIcon from '@mui/icons-material/Search'
import { COLORS } from '../constants'
import { styled } from '@mui/material/styles'

const TextField = styled(MuiTextField)(({ theme }) => ({
  height: '52px',
  [theme.breakpoints.down('md')]: {
    height: '40px',
    padding: '0 10px',
    paddingTop: '5px'
  },
  '& .MuiInput-input': {
    [theme.breakpoints.down('md')]: {
      fontSize: '14px'
    }
  }
}))
export default function SearchTextField ({
  placeholder,
  onSearchInputChange,
  searchValue
}) {
  return (
    <TextField
      fullWidth
      placeholder={placeholder}
      onChange={(e) => onSearchInputChange(e.target.value)}
      value={searchValue}
      InputProps={{
        startAdornment: (
          <InputAdornment position="end">
            <SearchIcon style={{ color: COLORS.primary }} />
          </InputAdornment>
        ),
        disableUnderline: true
      }}
      variant="standard"
      sx={{
        borderRadius: '5px',
        backgroundColor: COLORS.primaryBackground,
        padding: { xs: '7px', md: '10px' }
      }}
    />
  )
}
