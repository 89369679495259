import { DateTime } from 'luxon'

export const getTodayStartOfDayDate = () =>
  DateTime.now().startOf('day').toISO()

export const getTodayEndOfDayDate = () => DateTime.now().endOf('day').toISO()

export const getTodayDay = () => DateTime.now().setLocale('ar-EG').weekdayLong

export const getTodayDate = () =>
  DateTime.now().setLocale('ar-EG').toFormat('dd/MM/yyyy')

export const getFormattedDate = (isoDate) => {
  if (!isoDate) return ''
  const date = DateTime.fromISO(isoDate).toFormat('yyyy/MM/dd (HH:mm)')
  return date
}

export const getDate = (isoDate) => {
  if (!isoDate) return ''
  const date = DateTime.fromISO(isoDate).toFormat('dd/MM/yyyy ')
  return date
}
