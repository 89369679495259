import { axiosClient } from './base'
const USER_REGISTER = '/api/register'

export const register = ({
  userName, companyName, phoneNumber, email, password, passwordConfirmation, address, governorateId, cityId
}) =>
  axiosClient.post(USER_REGISTER, {
    userName,
    companyName,
    phoneNumber,
    email,
    password,
    passwordConfirmation,
    address,
    governorateId,
    cityId
  })
