import Button from '@mui/material/Button'
import { COLORS } from '../constants'

export default function ActionButton({ children, onClick, ...props }) {
  return (
    <Button
      variant="contained"
      fullWidth
      onClick={onClick}
      color="primary"
      style={{
        backgroundColor: COLORS.primary,
        borderRadius: '3px',
        float: 'left'
      }}
      sx={{
        height: { xs: '38px', md: '44px' },
        width: '160px',
        fontSize: { xs: '12px', md: '16px' },
        display: { xs: 'none', md: 'flex' },
        justifyContent: 'center',
        alignItems: 'center'
      }}
      {...props}
    >
      {children}
    </Button>
  )
}
