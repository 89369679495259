import * as React from 'react'
// import { useDispatch, useSelector } from 'react-redux'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Modal from '@mui/material/Modal'
import Fade from '@mui/material/Fade'
import Typography from '@mui/material/Typography'
import CancelButton from './CancelButton'
import ConfirmButton from './ConfirmButton'
import { COLORS } from './../constants'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: { xs: '90%', sm: '80%', md: '60%', lg: '40%' },
  bgcolor: COLORS.background,
  borderRadius: '10px',
  boxShadow: 24,
  p: { xs: '16px', sm: '28px', md: '40px' }
}

export default function DeleteModal ({
  open,
  handleClose,
  handleDelete,
  title
}) {
  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Fade in={open}>
          <Box sx={style}>
            <Grid container direction={'column'}spacing={{ xs: 4, sm: 5.5 }}>
              <Grid item>
                <Typography fontSize={{ xs: '14px', md: '18px' }} color={COLORS.secondary}>
                  هل تريد بالفعل حذف هذا الصف من جدول {title} ؟
                </Typography>
              </Grid>
              <Grid
                item
                container
                direction={'row'}
                spacing={3}
                display={'flex'}
                justifyContent={'flex-end'}
                alignItems={'center'}
              >
                <ConfirmButton label={'حذف'} onClick={handleDelete} />
                <CancelButton label={'إلغاء'} onClick={handleClose} />
              </Grid>
            </Grid>
          </Box>
        </Fade>
      </Modal>
    </div>
  )
}
