
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { makeStyles } from '@mui/styles'
import MuiAppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import IconButton from '@mui/material/IconButton'
import MenuItem from '@mui/material/MenuItem'
import Menu from '@mui/material/Menu'
import AccountCircle from '@mui/icons-material/AccountCircle'
import { COLORS } from '../constants'
import CustomH1 from '../components/CustomH1'
import { styled } from '@mui/material/styles'
import MenuIcon from '@mui/icons-material/Menu'
import { useSelector } from 'react-redux'
import { selectCompanyProfile, selectUserProfile } from '../features/profile/selectors'
import { Typography } from '@mui/material'

const drawerWidth = 240 // 240

const useStyles = makeStyles((theme) => ({
  menuButton: {
    '&:hover': {
      background: '#282828'
    }
  },
  hide: {
    display: 'none'
  },
  grow: {
    flexGrow: 1
  }
}))

const AppBar = styled(MuiAppBar, { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    // transition: theme.transitions.create(['width', 'margin'], {
    //   easing: theme.transitions.easing.sharp,
    //   duration: theme.transitions.duration.enteringScreen
    // }),
    transition: 'display 2s',
    [theme.breakpoints.up('lg')]: {
      width: 'calc(100% - 200px)'
    },
    [theme.breakpoints.down('md')]: {
      width: 'calc(100% - 200px)'
    },
    [theme.breakpoints.down('sm')]: {
      width: 'calc(100% - 190px)'
    },
    '& .MuiTypography-root': {
      [theme.breakpoints.down('md')]: {
        display: 'none'
      }
    }
  }),
  ...(!open && {
    transition: 'all 0.5s'
  }),
  '& .MuiToolbar-root': {
    [theme.breakpoints.down('md')]: {
      paddingRight: '12px !important'
    },
    [theme.breakpoints.down('sm')]: {
      paddingRight: '22px !important'
    }
  }
}))
export default function AppLayout ({ open, handleDrawerOpen }) {
  const classes = useStyles()
  const navigate = useNavigate()
  const companyProfile = useSelector(selectCompanyProfile)
  const userProfile = useSelector(selectUserProfile)
  const [anchorEl, setAnchorEl] = useState(null)

  const isMenuOpen = Boolean(anchorEl)

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleMenuClose = () => {
    setAnchorEl(null)
  }
  const handleLogout = () => {
    navigate('logout')
    setAnchorEl(null)
  }

  const handleProfile = () => {
    navigate('/profile')
    setAnchorEl(null)
  }
  const menuId = 'profile-menu'

  return (
    <>
      <AppBar
        open={open}
        position="fixed"
        sx={{
          backgroundColor: COLORS.background,
          boxShadow: '0 0'
        }}
      >
        <Toolbar
          style={{
            paddingLeft: 60,
            paddingRight: 20
          }}
        >
          <IconButton
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: 2,
              paddingLeft: { xs: 1, md: 2 },
              paddingRight: 2,
              ...(open && { display: 'none' })
            }}
            style={{
              color: COLORS.primary,
              width: '50px',
              height: '50px'
            }}
          >
            <MenuIcon />
          </IconButton>

          <CustomH1 fontSize={{ xs: '20px !important', sm: '25px !important' }} marginRight={5} >
                {companyProfile.name}
          </CustomH1>
          <div className={classes.grow} />
          <Typography component={'h3'} color='text.secondary'>{userProfile.name}</Typography>
          <IconButton
            aria-label="account of current user"
            aria-controls={menuId}
            aria-haspopup="true"
            onClick={handleMenuOpen}
            style={{
              color: COLORS.primary
            }}
          >
            <AccountCircle />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Menu
        anchorEl={anchorEl}
        elevation={0}
        id={menuId}
        keepMounted
        transformOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        open={isMenuOpen}
        onClose={handleMenuClose}
      >
        <MenuItem onClick={handleProfile}>حسابي</MenuItem>
        <MenuItem onClick={handleLogout}>تسجيل الخروج</MenuItem>
      </Menu>
    </>
  )
}
