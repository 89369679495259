import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'

import { logoutAsync } from '../auth/slice'
import LoadingSpinner from '../../components/LoadingSpinner'

export default function Logout () {
  const dispatch = useDispatch()

  useEffect(() => dispatch(logoutAsync()), [dispatch])

  return <LoadingSpinner />
}
