import axios from 'axios'
import { getFirebaseAuth } from '../utils/firebase'
import { setUser } from '../features/auth/slice'

const axiosClient = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json'
  }
})

axiosClient.interceptors.request.use(async function (config) {
  const auth = getFirebaseAuth()
  const token = await auth.currentUser?.getIdToken()
  config.headers.Authorization = `Bearer ${token}`
  return config
})

axiosClient.interceptors.response.use(
  function (response) {
    return response.data
  },
  function (error) {
    if (error && error.response && error.response.status === 401) {
      setUser(null)
    }
    if (error && error.response && error.response.data) {
      const data = error.response.data
      return Promise.reject(data)
    }
    return Promise.reject(error)
  }
)

export { axiosClient }
