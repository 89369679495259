export const selectError = (state) => state.user.error
export const selectUsers = (state) => state.user.users
export const selectCompany = (state) => state.user.selectedCompany
export const selectStatus = (state) => state.user.status
export const selectListUsersData = (state) => {
  return {
    totalCount: state.user.totalCount,
    pageNumber: state.user.pageNumber,
    pageSize: state.user.pageSize,
    q: state.user.q
  }
}
