import { axiosClient } from './base'

const LIST_COMPANIES = '/admin/api/company'

export const listCompanies = ({ pageNumber, pageSize, q }) =>
  axiosClient.get(LIST_COMPANIES, {
    params: {
      pageNumber,
      pageSize,
      q
    }
  })

export const getCompany = (id) =>
  axiosClient.get(`/admin/api/company/${id}`)
