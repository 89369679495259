import React from 'react'
import TableCell from '@mui/material/TableCell'

export default function CustomTableCell (props) {
  const { children, ...rest } = props
  return (
    <TableCell
      style={{
        textAlign: 'right'
      }}
      sx={{
        bgcolor: 'white',
        color: 'text.black',
        borderBottom: '1px solid #D8D8D8',
        py: { xs: 1, md: '16px' },
        px: { xs: 3, md: '8px' },
        fontSize: { xs: '12px', md: '14px' }
      }}
      {...rest}
    >
      {children}
    </TableCell>
  )
}
