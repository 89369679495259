import { configureStore } from '@reduxjs/toolkit'
import authReducer from './features/auth/slice'
import profileReducer from './features/profile/slice'
import companyReducer from './features/company/slice'
import userReducer from './features/user/slice'
import contactReducer from './features/contact/slice'

export const store = configureStore({
  reducer: {
    auth: authReducer,
    company: companyReducer,
    profile: profileReducer,
    user: userReducer,
    contact: contactReducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false })
})
