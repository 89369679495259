import { axiosClient } from './base'

const GET_PROFILE = '/api/user/me'
const LIST_COMPANY_USERS = '/api/company/me/user'
const CREATE_NEW_COMPANY_USER = '/api/company/me/user'
const UPDATE_COMPANY_USER = (id) => `/api/company/me/user/${id}`
const DELETE_COMPANY_USER = (id) => `/api/company/me/user/${id}`

export const getProfile = () => axiosClient.get(GET_PROFILE)
export const getCompanyUsers = () => axiosClient.get(LIST_COMPANY_USERS)

export const updateCompany = ({
  address,
  governorateId,
  cityId,
  companyName
}) =>
  axiosClient.put('/api/company/me/update-profile', {
    address,
    governorateId,
    cityId,
    companyName
  })

export const updateProfile = ({ userName, phoneNumber, email }) =>
  axiosClient.put('/api/user/me', {
    userName,
    phoneNumber,
    email,
    isActive: true
  })

export const updatePassword = ({ password, passwordConfirmation }) =>
  axiosClient.put('/api/user/me/password', { password, passwordConfirmation })

export const addNewCompanyUser = ({
  userName,
  phoneNumber,
  email,
  password,
  passwordConfirmation
}) =>
  axiosClient.post(CREATE_NEW_COMPANY_USER, {
    userName,
    phoneNumber,
    email,
    password,
    passwordConfirmation
  })

export const editCompanyUser = (companyUserId, companyUserDetails) =>
  axiosClient.put(UPDATE_COMPANY_USER(companyUserId), companyUserDetails)

export const deleteCompanyUser = (companyUserId) =>
  axiosClient.delete(DELETE_COMPANY_USER(companyUserId))
