import * as React from 'react'
import { useSelector } from 'react-redux'
import {
  useLocation,
  Navigate
} from 'react-router-dom'

import { selectCurrentUser } from '../features/auth/selectors'

export default function AuthRoute ({ children }) {
  const currentUser = useSelector(selectCurrentUser)
  const location = useLocation()
  const from = location.state?.from?.pathname || ''

  if (currentUser) {
    if (from && from !== '/logout') {
      return <Navigate to={from} state={{ replace: true }}/>
    }
    return <Navigate to="/" />
  }
  return children
}
