import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import {
  STATE_IDLE,
  STATE_SUCCEEDED,
  STATE_FAILED,
  STATE_LOADING
} from '../../constants'
import { listCompanies } from '../../api/company'

const initialState = {
  companies: [],
  totalCount: 0,
  pageNumber: 1,
  pageSize: 100,
  q: '',
  status: STATE_IDLE,
  error: null
}

export const listCompaniesAsync = createAsyncThunk(
  'company/list',
  async ({ pageSize, pageNumber, q }) => {
    const listResult = await listCompanies({
      pageSize,
      pageNumber,
      q
    })
    return listResult.data
  }
)

export const companySlice = createSlice({
  name: 'company',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(listCompaniesAsync.pending, (state, action) => {
        state.status = STATE_LOADING
      })
      .addCase(listCompaniesAsync.fulfilled, (state, action) => {
        state.status = STATE_SUCCEEDED
        state.companies = action.payload.companies
        state.totalCount = action.payload.totalCount
        state.pageNumber = action.payload.pageNumber
        state.pageSize = action.payload.pageSize
        state.q = action.payload.q
      })
      .addCase(listCompaniesAsync.rejected, (state, action) => {
        state.status = STATE_FAILED
        console.error(action.error)
        state.error = {
          message: action.error
        }
      })
  }
})

export default companySlice.reducer
