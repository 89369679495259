import { Grid } from '@mui/material'
import { useCallback, useState } from 'react'
import toast from 'react-hot-toast'
import { updatePassword } from '../../api/profile'
import CancelButton from '../../components/CancelButton'
import ConfirmButton from '../../components/ConfirmButton'
import CustomTextField from '../../components/CustomTextField'
import LabelTypography from '../../components/LabelTypography'
import LoadingSpinner from '../../components/LoadingSpinner'

export default function ManagePasswordSection () {
  const [data, setData] = useState({ password: '', confirmPassword: '' })
  const [isLoading, setIsLoading] = useState(false)

  const onChange = useCallback(
    (e) => {
      setData({
        ...data,
        [e.target.name]: e.target.value
      })
    },
    [data]
  )

  const onSubmit = useCallback(async () => {
    setIsLoading(true)
    try {
      await updatePassword({
        password: data.password,
        passwordConfirmation: data.confirmPassword
      })
      toast.success('تم تحديث البيانات بنجاح')
    } catch (error) {
      console.error(error)
      toast.error('حدث خطآ ما برجاء المحاولة مره اخري')
    }
    setIsLoading(false)
  }, [data])

  const onCancel = useCallback(() => setData({ password: '', confirmPassword: '' }), [])

  if (isLoading) {
    return <LoadingSpinner />
  }

  return (
    <>
      <Grid item xs={12}>
        <LabelTypography
          sx={{
            color: 'text.primary',
            fontSize: '20px',
            fontWeight: 'bold'
          }}
        >
          ادارة كلمة المرور
        </LabelTypography>
      </Grid>
      <Grid item xs={6}>
        <CustomTextField
          required
          fullWidth
          label="كلمة المرور الجديدة"
          name="password"
          type="password"
          onChange={onChange}
          value={data.password}
        />
      </Grid>
      <Grid item xs={6}>
        <CustomTextField
          required
          fullWidth
          label="تأكيد كلمة المرور الجديدة"
          name="confirmPassword"
          type="password"
          onChange={onChange}
          value={data.confirmPassword}
        />
      </Grid>
      <Grid item xs={12} display={'flex'} justifyContent={'flex-end'}>
        <ConfirmButton
          label={'حفظ'}
          onClick={onSubmit}
        />
        <CancelButton
          label={'إلغاء'}
          onClick={onCancel}
        />
      </Grid>
    </>
  )
}
