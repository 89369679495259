import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import {
  signInWithEmailAndPassword,
  browserLocalPersistence,
  setPersistence,
  signOut
} from 'firebase/auth'

import { getFirebaseAuth } from '../../utils/firebase'
import {
  STATE_FAILED,
  STATE_LOADING,
  STATE_SUCCEEDED
} from '../../constants'

const initialState = {
  currentUser: null,
  status: STATE_LOADING,
  error: null
}

export const loginAsync = createAsyncThunk(
  'auth/login',
  async ({ email, password }) => {
    await setPersistence(getFirebaseAuth(), browserLocalPersistence)
    const userCredentials = await signInWithEmailAndPassword(
      getFirebaseAuth(),
      email,
      password
    )
    return userCredentials.user
  }
)

export const logoutAsync = createAsyncThunk('auth/logout', async () => {
  await signOut(getFirebaseAuth())
})

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.status = STATE_SUCCEEDED
      if (action.payload) {
        state.currentUser = {
          id: action.payload.uid,
          name: action.payload.displayName,
          phoneNumber: action.payload.phoneNumber,
          email: action.payload.email
        }
      } else {
        state.currentUser = null
      }
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginAsync.pending, (state, action) => {
        state.status = STATE_LOADING
      })
      .addCase(loginAsync.fulfilled, (state, action) => {
        state.status = STATE_SUCCEEDED
        state.currentUser = {
          id: action.payload.uid,
          name: action.payload.displayName,
          phoneNumber: action.payload.phoneNumber,
          email: action.payload.email
        }
      })
      .addCase(loginAsync.rejected, (state, action) => {
        state.status = STATE_FAILED
        console.error(action.error)
        let message
        switch (action.error.code) {
          case 'auth/user-not-found':
            message = 'اسم المستخدم غير موجود ، برجاء التواصل مع ادارة الموقع'
            break
          default:
            message =
              'فشل تسجيل الدخول برجاء التآكد من اسم المستخدم وكلمة المرور'
        }
        state.error = {
          message
        }
      })
      .addCase(logoutAsync.fulfilled, (state) => {
        state.status = STATE_SUCCEEDED
        state.currentUser = null
      })
      .addCase(logoutAsync.rejected, (state, action) => {
        state.status = STATE_FAILED
        state.error = {
          message: action.error.message,
          code: action.error.code
        }
      })
  }
})

export const { setUser } = authSlice.actions

export default authSlice.reducer
