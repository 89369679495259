import { Grid, Typography } from '@mui/material'
import { Box } from '@mui/system'
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import SearchTextField from '../../components/SearchTextField'
import CompaniesTable from './CompaniesTable'
import { listCompaniesAsync } from './slice'

export default function List () {
  const [searchValue, setSearchValue] = useState('')
  const dispatch = useDispatch()

  const onSearchInputChange = (value) => {
    setSearchValue(value)
    dispatch(
      listCompaniesAsync({
        pageSize: 100,
        pageNumber: 1,
        q: value || ''
      })
    )
  }

  return (
    <>
      <Box
        sx={{
          pl: { xs: '16px', md: '24px', lg: '24px' },
          pr: { xs: '16px', md: '24px', lg: '24px' },
          mb: '40px',
          position: 'relative'
        }}
      >
        <Grid
          container
          padding={0}
          justifyContent="space-between"
        >
          <Grid item xs={12} marginBottom={3} marginTop={3}>
            <Typography
              sx={{ fontSize: '20px', fontWeight: 'bold', color: 'text.black' }}
            >
              الشركات
            </Typography>
          </Grid>

          <Grid
            item
            xs={12}
            md={5}
            sx={{ marginBottom: { xs: '8px', md: '16px' } }}
          >
            <SearchTextField
              placeholder={'بحث'}
              onSearchInputChange={onSearchInputChange}
              searchValue={searchValue}
            />
          </Grid>

          <Grid item display={{ xs: 'none', sm: 'block' }}>
            {/* <ActionButton onClick={handleOpen}>أضف خامة</ActionButton> */}
          </Grid>
        </Grid>

        <CompaniesTable />
      </Box>
      {/* <AddButton showAddModal={handleOpen} label={'add item'} /> */}
      {/* <AddNewItem handleClose={handleClose} open={open} /> */}
    </>
  )
}
