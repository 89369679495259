import { Box, Grid } from '@mui/material'
import { useEffect, useState, useCallback } from 'react'
import toast from 'react-hot-toast'
import { useSelector } from 'react-redux'
import { updateProfile } from '../../api/profile'
import CancelButton from '../../components/CancelButton'
import ConfirmButton from '../../components/ConfirmButton'
import CustomTextField from '../../components/CustomTextField'
import LoadingSpinner from '../../components/LoadingSpinner'
import { STATE_SUCCEEDED } from '../../constants'
import { selectUserProfile, selectGetProfileStatus } from './selectors'

export default function ManageAccountSection () {
  const userProfile = useSelector(selectUserProfile)
  const getProfileStatus = useSelector(selectGetProfileStatus)
  const [data, setData] = useState()
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    setData(userProfile)
  }, [userProfile])

  const onChange = useCallback(
    (e) => {
      setData({
        ...data,
        [e.target.name]: e.target.value
      })
    },
    [data]
  )

  const onSubmit = useCallback(async () => {
    setIsLoading(true)
    try {
      await updateProfile({
        userName: data.name,
        email: data.email,
        phoneNumber: data.phoneNumber
      })
      toast.success('تم تحديث البيانات بنجاح')
    } catch (error) {
      console.error(error)
      toast.error('حدث خطآ ما برجاء المحاولة مره اخري')
    }
    setIsLoading(false)
  }, [data])

  const onCancel = useCallback(() => setData(userProfile), [userProfile])

  if (getProfileStatus !== STATE_SUCCEEDED || !data || isLoading) {
    return <LoadingSpinner />
  }

  return (
    <>
      <Grid item xs={6}>
        <CustomTextField
          required
          fullWidth
          label="البريد الالكتروني"
          name="email"
          autoFocus
          onChange={onChange}
          value={data.email}
        />
      </Grid>

      <Grid item xs={6}>
        <CustomTextField
          required
          fullWidth
          label="رقم التليفون"
          name="phoneNumber"
          onChange={onChange}
          value={data.phoneNumber}
        />
      </Grid>
      <Grid item xs={6}>
        <CustomTextField
          required
          fullWidth
          label="الاسم"
          name="name"
          onChange={onChange}
          value={data.name}
        />
      </Grid>
      <Grid
        item
        xs={6}
        display={'flex'}
        justifyContent={'flex-end'}
      >
        <Box mt={2}>
          <ConfirmButton
            label={'حفظ'}
            onClick={onSubmit}
          />
          <CancelButton
            label={'إلغاء'}
            onClick={onCancel}
          />
        </Box>
      </Grid>
    </>
  )
}
