import { Grid } from '@mui/material'
import { Box } from '@mui/system'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import SearchTextField from '../../components/SearchTextField'
import UsersTable from './UsersTable'
import { listUsersAsync } from './slice'
import { useParams } from 'react-router-dom'
import { selectCompany } from './selectors'
import PageHeader from '../../components/PageHeader'

export default function List () {
  const [searchValue, setSearchValue] = useState('')
  const dispatch = useDispatch()
  const params = useParams()
  const companyId = params.companyID
  const company = useSelector(selectCompany)

  const onSearchInputChange = (value) => {
    setSearchValue(value)
    if (value.length) {
      dispatch(
        listUsersAsync({
          companyId,
          pageSize: 25,
          pageNumber: 1,
          q: value
        })
      )
    }
    if (value.length === 0) {
      dispatch(
        listUsersAsync({
          companyId,
          pageSize: 25,
          pageNumber: 1,
          q: ''
        })
      )
    }
  }

  return (
    <>
      <Box
        sx={{
          pl: { xs: '16px', md: '24px', lg: '24px' },
          pr: { xs: '16px', md: '24px', lg: '24px' },
          mb: '40px',
          position: 'relative'
        }}
      >
        <PageHeader
          title={`عرض مستخدمين شركة ${company.name || ' '}`}
          backPath={'/'}
        />
        <Box>
          <Grid
            container
            padding={0}
            justifyContent="space-between"
            marginTop={2}
          >
            <Grid
              item
              xs={12}
              md={5}
              sx={{ marginBottom: { xs: '8px', md: '0px' } }}
            >
              <SearchTextField
                placeholder={'بحث'}
                onSearchInputChange={onSearchInputChange}
                searchValue={searchValue}
              />
            </Grid>
            <Grid item display={{ xs: 'none', sm: 'block' }}>
              {/* <ActionButton onClick={handleOpen}>أضف خامة</ActionButton> */}
            </Grid>
          </Grid>

          <UsersTable />
        </Box>
        {/* <AddButton showAddModal={handleOpen} label={'add item'} /> */}
        {/* <AddNewItem handleClose={handleClose} open={open} /> */}
      </Box>
    </>
  )
}
