import { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Grid } from '@mui/material'

import LabelTypography from '../../components/LabelTypography'
import ActionButton from '../project/ActionButton'
import UpsertCompanyUser from './UpsertCompanyUser'
import { STATE_SUCCEEDED } from '../../constants'
import { selectCompanyUsers, selectCompanyUsersStatus } from './selectors'
import LoadingSpinner from '../../components/LoadingSpinner'
import CompanyUser from './CompanyUser'
import DeleteModal from '../../components/DeleteModal'
import { deleteCompanyUser } from '../../api/profile'
import { listCompanyUsersAsync } from './slice'

export default function CompanyUsers () {
  const dispatch = useDispatch()
  const companyUsers = useSelector(selectCompanyUsers)
  const companyUsersStatus = useSelector(selectCompanyUsersStatus)

  const [open, setOpen] = useState(false)
  const handleOpen = () => setOpen(true)

  const [openDeleteModal, setOpenDeleteModal] = useState(false)
  const [companyUserDeleteData, setCompanyUserDeleteData] = useState('')
  const handleOpenDeleteModal = () => setOpenDeleteModal(true)
  const handleCloseDeleteModal = () => setOpenDeleteModal(false)

  const [companyUserData, setCompanyUserData] = useState('')
  const [isEditModal, setIsEditModal] = useState(false)

  const showAddModal = () => {
    handleOpen(true)
    setIsEditModal(false)
  }

  const showEditModal = (companyUser) => {
    setIsEditModal(true)
    setCompanyUserData(companyUser)
    handleOpen(true)
  }

  const showDeletetModal = (companyUser) => {
    setCompanyUserDeleteData(companyUser)
    handleOpenDeleteModal(true)
  }

  const handleClose = () => {
    setIsEditModal(!isEditModal)
    setOpen(false)
  }

  const handleDelete = async () => {
    try {
      await deleteCompanyUser(companyUserDeleteData.id)
      handleCloseDeleteModal()
      dispatch(listCompanyUsersAsync())
    } catch (error) {
      console.error(error)
    }
  }

  if (companyUsersStatus !== STATE_SUCCEEDED) {
    return <LoadingSpinner />
  }

  return (
    <>
      <Grid
        container
        marginTop={2}
        alignItems={'center'}
        justifyContent={'space-between'}
      >
        <Grid item>
          <LabelTypography
            sx={{
              color: 'text.primary',
              fontSize: '20px',
              fontWeight: 'bold'
            }}
          >
            المستخدمين
          </LabelTypography>
        </Grid>
        <Grid item>
          <ActionButton onClick={showAddModal}>أضف مستخدم</ActionButton>
        </Grid>
      </Grid>
      <Grid container mt={5}>
        {
          companyUsers.map(companyUser => (
            <CompanyUser
              companyUser={companyUser}
              key={companyUser.id}
              showEditCompanyUserModal={showEditModal}
              showDeleteCompanyUserModal={showDeletetModal}
            />
          )
          )}
      </Grid>
      <UpsertCompanyUser
        companyUser={companyUserData}
        open={open}
        handleClose={handleClose}
        isEditModal={isEditModal}
      />
      <DeleteModal
        title = {'المستخدمين'}
        open={openDeleteModal}
        handleClose={handleCloseDeleteModal}
        handleDelete={handleDelete}
      />
    </>
  )
}
