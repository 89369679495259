import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import PagePagination from '../../components/Pagination'
import ResourceLoading from '../../components/ResourceLoading'
import CustomTableCell from '../../components/TabelCell'
import { STATE_SUCCEEDED } from '../../constants'
import {
  selectCompanies,
  selectListCompaniesData,
  selectStatus
} from './selectors'
import { listCompaniesAsync } from './slice'
import { getDate, getFormattedDate } from '../../utils/date'
import HoverCell from '../../components/HoverCell'

const tableHead = [
  {
    id: 'sequence',
    label: 'م',
    minWidth: 5,
    align: 'right',
    borderTopLeftRadius: '5px'
  },
  { id: 'name', label: 'اسم الشركة', minWidth: '150px', align: 'right' },
  {
    id: 'startDate',
    label: ' بداية الاشتراك',
    minWidth: '150px',
    align: 'right'
  },
  {
    id: 'endDate',
    label: ' انتهاء الاشتراك',
    minWidth: '150px',
    align: 'right'
  },
  {
    id: 'lastActiveAt',
    label: 'آخر استخدام',
    minWidth: '150px',
    align: 'right'
  },
  // { id: 'plan', label: 'النسخة', minWidth: '20px', align: 'right' },
  { id: 'users', label: 'المستخدمين', minWidth: 20, align: 'right' },
  { id: 'projects', label: 'المشاريع', minWidth: 20, align: 'right' },
  { id: 'clients', label: 'العملاء', minWidth: 20, align: 'right' },
  { id: 'contractors', label: 'المقاولين', minWidth: 20, align: 'right' },

  { id: 'total', label: 'إجمالي الاشتراك', minWidth: '200px', align: 'right' },

  { id: 'governorate', label: 'المحافظة', minWidth: '50px', align: 'right' },
  {
    id: 'city',
    label: 'المدينة',
    minWidth: '30px',
    align: 'right',
    borderTopRightRadius: '5px'
  }
]

export default function ItemsTable() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const companies = useSelector(selectCompanies)
  const getItemsStatus = useSelector(selectStatus)

  const location = useLocation()
  const query = new URLSearchParams(location.search)
  const page = parseInt(query.get('page') || '1', 10)
  const listData = useSelector(selectListCompaniesData)

  useEffect(() => {
    const pageSize = 100
    dispatch(
      listCompaniesAsync({
        pageSize,
        pageNumber: page,
        q: ''
      })
    )
  }, [page])

  const onClickCompany = useCallback((companyId) => {
    return navigate(`/company/${companyId}/user`)
  }, [])
  if (getItemsStatus !== STATE_SUCCEEDED) {
    return <ResourceLoading />
  }

  return (
    <>
      <Paper
        sx={{
          marginTop: { xs: 1, sm: 0 },
          boxShadow: '0 0'
        }}
      >
        <TableContainer
          sx={{
            overflowX: 'scroll'
          }}
        >
          <Table
            stickyHeader
            aria-label="sticky table"
            sx={{
              boxShadow: '0px 1px 3px #00000029',
              borderTopLeftRadius: '5px',
              borderTopRightRadius: '5px',
              overflow: 'scroll'
            }}
          >
            <TableHead>
              <TableRow>
                {tableHead.map((column) => (
                  <TableCell
                    column={column}
                    key={column.id}
                    style={{
                      textAlign: 'right'
                    }}
                    sx={{
                      width: column.minWidth,
                      bgcolor: 'rgb(242 135 5 / 0.5)',
                      color: 'text.black',
                      borderTopLeftRadius: column.borderTopLeftRadius,
                      borderTopRightRadius: column.borderTopRightRadius,
                      fontSize: '14px'
                    }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody
              sx={{
                overflow: 'scroll'
              }}
            >
              {companies.map((company, index) => {
                return (
                  <TableRow key={index}>
                    <CustomTableCell>{index + 1}</CustomTableCell>
                    <HoverCell
                      onClick={() =>
                        navigate(`company/${company.id}`, {
                          state: { company }
                        })
                      }
                    >
                      {company.name}
                    </HoverCell>
                    <CustomTableCell>
                      {getDate(company?.activeSubscriptionStartDate)}
                    </CustomTableCell>
                    <CustomTableCell>
                      {getDate(company?.activeSubscriptionEndDate)}
                    </CustomTableCell>
                    <CustomTableCell>
                      {getFormattedDate(company?.lastActiveAt)}
                    </CustomTableCell>
                    {/* <CustomTableCell>المتطورة</CustomTableCell> */}
                    <HoverCell onClick={() => onClickCompany(company.id)}>
                      {company.usersCount}
                    </HoverCell>
                    <CustomTableCell>{company.projectsCount}</CustomTableCell>
                    <CustomTableCell>{company.clientsCount}</CustomTableCell>
                    <CustomTableCell>
                      {company.contractorsCount}
                    </CustomTableCell>
                    <CustomTableCell>
                      {company?.activeSubscriptionAmountPaid} ج
                    </CustomTableCell>
                    <CustomTableCell>
                      {company.governorate?.name}
                    </CustomTableCell>
                    <CustomTableCell>{company.city?.name}</CustomTableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>

      <PagePagination
        page={page}
        pageSize={listData.pageSize}
        totalCount={listData.totalCount}
      />
    </>
  )
}
