import React, { useState, useCallback, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { object, number, string, ref } from 'yup'
import { Select, FormControl, MenuItem, InputLabel } from '@mui/material'
import Button from '@mui/material/Button'
import CssBaseline from '@mui/material/CssBaseline'
import Link from '@mui/material/Link'
import Grid from '@mui/material/Grid'
import { styled } from '@mui/material/styles'
import Container from '@mui/material/Container'
import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import { ReactComponent as ReactLogo } from './home.svg'
import { COLORS } from '../../constants'
import CustomTypographyH1 from '../../components/CustomH1'
import { convertToEnglishNumber } from './../../utils/number'
import { getGovernorates } from './../../api/governorates'
import { register } from '../../api/register'
import { loginAsync } from './slice'
import Alert from '@mui/material/Alert'
import Stack from '@mui/material/Stack'

const ImgBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  [theme.breakpoints.down('sm')]: {
    display: 'none'
  }
}))
const StyledButton = styled(Button)(({ theme }) => ({
  width: '350px',
  height: '45px',
  [theme.breakpoints.down('sm')]: {
    width: '335px',
    height: '40px'
  },
  [theme.breakpoints.between('sm', 'md')]: {
    width: '370px'
  }
}))
const StyledTextField = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    borderRadius: '10px',
    height: '45px',
    width: '390px',
    color: '#000',
    border: 'none',
    backgroundColor: COLORS.primaryBackground,
    [theme.breakpoints.between('sm', 'md')]: {
      width: '370px'
    },
    [theme.breakpoints.down('sm')]: {
      height: '50px',
      width: '335px'
    }
  },
  '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
    border: 'none'
  }
}))

const StyledFormControl = styled(FormControl)(({ theme }) => ({
  '& .MuiInputBase-root': {
    borderRadius: '10px',
    height: '45px',
    width: '185px',
    color: '#000',
    backgroundColor: COLORS.primaryBackground,
    [theme.breakpoints.down('md')]: {
      width: '175px'
    },
    [theme.breakpoints.down('sm')]: {
      height: '50px',
      width: '157.5px'
    }
  },
  '& .MuiInputBase-root .MuiOutlinedInput-notchedOutline': {
    border: 'none'
  },
  '& .MuiInputLabel-formControl': {
    color: '#9A9A9A'
  }
}))

const newUserSchema = object({
  cityId: string().required('يجب اختيار مدينه'),
  governorateId: string().required('يجب اختيار المحافظه'),
  address: string().required('يجب ادخال العنوان'),
  company: string().required('يجب ادخال اسم االشركه'),
  phone: number().required().typeError('يجب ادخال رقم التلفون'),
  username: string().required('يجب ادخال اسم المستخدم'),
  confirmPass: string().required('برجاء تأكيد كلمه المرور').oneOf([ref('password')], 'يجب ان تكون كلمه المرور متطابقه'),
  password: string().required('يجب ادخال كلمه المرور').min(6, 'يجب ان لا تقل كلمه المرور عن سته ارقام'),
  email: string().email('ادخل ايميل صحيح').required('يجب ادخال الايميل')
})
export default function Register () {
  const dispatch = useDispatch()
  const [userData, setUserData] = useState({
    username: '',
    email: '',
    password: '',
    confirmPass: '',
    phone: '',
    company: '',
    governorateId: '',
    cityId: '',
    address: ''
  })
  const [errorMsg, setErrorMsg] = useState('')
  const [emailExist, setEmailExist] = useState('')
  const [isValid, setIsValid] = useState(false)
  const [governorates, setGovernorate] = useState([])
  const [cities, setCities] = useState([])

  useEffect(async () => {
    try {
      const res = await getGovernorates()
      setGovernorate(res.data.governorates)
    } catch (error) {
      console.error(error)
    }
  }, [])

  const onInputChange = useCallback(
    (prop, value) => {
      setUserData({
        ...userData,
        [prop]: value
      })
    },
    [userData]
  )

  const onGovernorateChange = (value) => {
    setUserData({
      ...userData,
      governorateId: value
    })
    const governorat = governorates.find(
      (governorat) => governorat.id === value
    )
    setCities(governorat.city)
  }

  const email = userData.email
  const password = userData.password

  const onSubmit = useCallback(async (e) => {
    e.preventDefault()
    try {
      newUserSchema.validateSync(userData)
      newUserSchema.isValid(userData)
      setIsValid(true)
    } catch (error) {
      const res = JSON.stringify(error)
      const msg = JSON.parse(res)
      setErrorMsg(msg.message)
      setIsValid(false)
    }
    try {
      await register({
        userName: userData.username,
        companyName: userData.company,
        phoneNumber: userData.phone,
        email: userData.email,
        password: userData.password,
        passwordConfirmation: userData.confirmPass,
        address: userData.address,
        governorateId: userData.governorateId,
        cityId: userData.cityId
      })
      dispatch(loginAsync({ email, password }))
      clearInputValue()
    } catch (error) {
      console.error(error)
      if (error.code) {
        setEmailExist(error)
      } else {
        setEmailExist(' ')
      }
    }
  })
  const clearInputValue = useCallback(() => {
    setUserData({
      username: '',
      email: '',
      password: '',
      confirmPass: '',
      phone: '',
      company: '',
      governorateId: '',
      cityId: '',
      address: ''
    })
  })
  console.log(errorMsg)
  return (
    <Container component="main" overFlow={'hidden'}>
      <CssBaseline />
      <Stack sx={{
        width: '50%',
        margin: '5px auto'
      }}
      spacing={2}>
      {
        errorMsg && !isValid
          ? <Alert severity="warning">{errorMsg}</Alert>
          : emailExist ? <Alert severity="warning">هذا الايميل موجود بالفعل</Alert>
            : isValid && <Alert severity="success">تم تسجيل الدخول بنجاح</Alert>
      }
    </Stack>
      <Grid container direction={'column'} sx={{ mt: { xs: '50px', sm: '0' } }} >

        <Grid
          item
          xs={12}
          display={'flex'}
          justifyContent={'center'}
          alignItems={'center'}
          sx={{ mt: { xs: '10px', sm: '0' } }}
        >
          <ImgBox>
            <ReactLogo width={'500'} height={'210'} />
          </ImgBox>
        </Grid>

        <Grid
          item
          container
          direction={'column'}
          xs={12}
          sx={{ mt: { xs: '10px', sm: '8px' } }}
          display={'flex'}
          justifyContent={'center'}
          alignItems={'center'}
        >
          <Grid item>
            <CustomTypographyH1
              fontWeight={'bold'}
              sx={{
                fontSize: { xs: '22px !important', sm: '25px !important' },
                marginBottom: { xs: '20px', lg: '10px' }
              }} >
              انشاء حساب جديد
            </CustomTypographyH1>
          </Grid>
          <form method='POST'>
            <Grid
              item
              container
              direction={'column'}
              display={'flex'}
              justifyContent={'center'}
              alignItems={'center'}
            >
              <Grid
                item
                container
                direction={'row'}
                spacing={{ xs: 2.5, sm: 2.5, md: 8, lg: 17.5 }}
                marginBottom={2.5}
                sx={{ mb: { sm: 1.7 } }}
                display={'flex'}
                justifyContent={'center'}
                alignItems={'center'}
              >
                <Grid item >
                  <StyledTextField
                    required
                    id="email"
                    placeholder="البريد الالكتروني"
                    name="email"
                    value={userData.email}
                    autoComplete="email"
                    onChange={(e) => onInputChange('email', e.target.value)}
                  />
                </Grid>
                <Grid item>
                  <StyledTextField
                    required
                    id="username"
                    placeholder=" اسم المستخدم "
                    name="username"
                    value={userData.username}
                    onChange={(e) => onInputChange('username', e.target.value)}
                  />
                </Grid>
              </Grid>

              <Grid
                item
                container
                direction={'row'}
                spacing={{ xs: 2.5, sm: 2.5, md: 8, lg: 17.5 }}
                marginBottom={2.5}
                sx={{ mb: { sm: 1.7 } }}
                display={'flex'}
                justifyContent={'center'}
                alignItems={'center'}
              >
                <Grid item>
                  <StyledTextField
                    required
                    id="password"
                    type="password"
                    placeholder="كلمه المرور"
                    name="password"
                    value={userData.password}
                    onChange={(e) => onInputChange('password', e.target.value)}
                  />
                </Grid>
                <Grid item>
                  <StyledTextField
                    required
                    type="password"
                    id="confirmPass"
                    placeholder=" تأكيد كلمه المرور "
                    name="confirmPass"
                    value={userData.confirmPass}
                    onChange={(e) =>
                      onInputChange('confirmPass', e.target.value)
                    }
                  />
                </Grid>
              </Grid>

              <Grid
                item
                container
                direction={'row'}
                spacing={{ xs: 2.5, sm: 2.5, md: 8, lg: 17.5 }}
                marginBottom={2.5}
                sx={{ mb: { sm: 1.7 } }}
                display={'flex'}
                justifyContent={'center'}
                alignItems={'center'}
              >
                <Grid item>
                  <StyledTextField
                    required
                    id="company"
                    placeholder="اسم الشركه"
                    name="company"
                    value={userData.company}
                    onChange={(e) => onInputChange('company', e.target.value)}
                  />
                </Grid>
                <Grid item>
                  <StyledTextField
                    required
                    id="phone"
                    placeholder=" رقم الهاتف"
                    name="phone"
                    value={userData.phone}
                    onChange={(e) =>
                      onInputChange(
                        'phone',
                        convertToEnglishNumber(e.target.value)
                      )
                    }
                  />
                </Grid>
              </Grid>

              <Grid
                item
                container
                direction={'row'}
                spacing={{ xs: 2.5, sm: 2.5, md: 8, lg: 17.5 }}
                marginBottom={2.5}
                sx={{ mb: { sm: 1.7 } }}
                display={'flex'}
                justifyContent={'center'}
                alignItems={'center'}
              >
                <Grid item>
                  <StyledTextField
                    required
                    id="address"
                    placeholder="العنوان"
                    name="address"
                    value={userData.address}
                    onChange={(e) => onInputChange('address', e.target.value)}
                  />
                </Grid>

                <Grid
                  item
                  containe
                  display={'flex'}
                  justifyContent={'center'}
                  alignItems={'center'}
                  gap={'20px'}
                >
                  <Grid item>
                    <StyledFormControl>
                      <InputLabel>المحافظه</InputLabel>
                      <Select
                        name="governorateId"
                        value={userData.governorateId}
                        onChange={(e) => onGovernorateChange(e.target.value)}
                      >
                        {governorates.map((data) => (
                          <MenuItem value={data.id} key={data.id}>
                            {data.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </StyledFormControl>
                  </Grid>
                  <Grid item>
                    <StyledFormControl>
                      <InputLabel>المدينه</InputLabel>
                      <Select
                        name="cityId"
                        value={userData.cityId}
                        onChange={(e) =>
                          onInputChange('cityId', e.target.value)
                        }
                      >
                        {cities.map((data) => (
                          <MenuItem value={data.id} key={data.id}>
                            {data.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </StyledFormControl>
                  </Grid>
                </Grid>
              </Grid>

              <Grid
                item
                display={'flex'}
                marginBottom={2.5}
                sx={{ mb: { sm: 1.7 } }}
                justifyContent={'center'}
                alignItems={'center'}
              >
                <StyledButton
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  style={{
                    backgroundColor: COLORS.primary,
                    borderRadius: '10px'
                  }}
                  onClick={onSubmit}
                >
                  تسجيل
                </StyledButton>
              </Grid>
            </Grid>
          </form>
        </Grid>

        <Grid
          item
          xs={12}
          display={'flex'}
          justifyContent={'center'}
          alignItems={'center'}
          marginBottom={3}
        >
          <Link
            href="#"
            variant="body2"
            style={{
              color: COLORS.secondary
            }}
          >
            لديك حساب؟
          </Link>
          <Link
            href="/login"
            variant="body2"
            style={{
              color: COLORS.primary,
              marginRight: '7px'
            }}
          >
            تسجيل دخول
          </Link>
        </Grid>
      </Grid>
    </Container>
  )
}
