import Typography from '@mui/material/Typography'

export default function ModalLabelTypography ({ children, ...props }) {
  return (
    <Typography
      sx={{
        fontSize: { sm: '14px', md: '18px' },
        fontWeight: 'bold'
      }}
      {...props}
    >
      {children}
    </Typography>
  )
}
