import { Grid } from '@mui/material'
import Pagination from '@mui/material/Pagination'
import PaginationItem from '@mui/material/PaginationItem'
import { Link as RouterLink } from 'react-router-dom'
import {
  NavigateBeforeOutlined,
  NavigateNextOutlined
} from '@mui/icons-material'
import { COLORS } from '../constants'
import { useMemo } from 'react'

export default function PagePagination ({
  page,
  totalCount,
  pageSize,
  ...props
}) {
  const pagesCount = useMemo(() => {
    return (
      parseInt(totalCount / pageSize) + parseInt(totalCount % pageSize ? 1 : 0)
    )
  }, [totalCount, pageSize])

  return (
    <Grid container paddingTop={3} justifyContent={'center'} {...props}>
      <Grid item>
        <Pagination
          page={page}
          count={pagesCount}
          renderItem={(item) => (
            <PaginationItem
              component={RouterLink}
              to={`${item.page === 1 ? '' : `?page=${item.page}`}`}
              {...item}
              components={{
                previous: NavigateBeforeOutlined,
                next: NavigateNextOutlined
              }}
              sx={{
                '&.Mui-selected': {
                  backgroundColor: COLORS.background,
                  color: COLORS.primary
                },
                color: COLORS.third
              }}
            />
          )}
        />
      </Grid>
    </Grid>
  )
}
