import Button from '@mui/material/Button'
import AddIcon from '@mui/icons-material/Add'
import { COLORS } from '../../constants'

export default function ActionButton ({ children, onClick, ...props }) {
  return (
    <Button
      variant="contained"
      fullWidth
      startIcon={<AddIcon sx={{ marginLeft: 1 }} />}
      onClick={onClick}
      color="primary"
      style={{
        backgroundColor: COLORS.primary,
        borderRadius: '5px',
        float: 'left'
      }}
      sx={{
        width: { xs: '150px', md: '156px' },
        height: { xs: '40px', md: '50px' },
        fontSize: { xs: '12px', md: '16px' }
      }}
      {...props}
    >
      {children}
    </Button>
  )
}
