import { axiosClient } from './base'

export const listContactUsMessages = ({ pageNumber, pageSize, q }) =>
  axiosClient.get('/admin/api/contact', {
    params: {
      pageNumber,
      pageSize,
      q
    }
  })
