import Skeleton from '@mui/material/Skeleton'
import Box from '@mui/material/Box'

export default function ResourceLoading () {
  return (
    <Box sx={{ p: 4 }}>
      <Skeleton height={70} />
      <Skeleton animation={false} height={70} />
      <Skeleton animation="wave" height={70} />
    </Box>
  )
}
