import React, { useEffect } from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import { getAuth, onAuthStateChanged } from 'firebase/auth'
import { useDispatch } from 'react-redux'

import Login from './features/auth/Login'
import Logout from './features/auth/Logout'
import Register from './features/auth/Register'

import ListCompanies from './features/company/List'
import ListUsers from './features/user/List'

import ListContactUs from './features/contact-us/List'

import { setUser } from './features/auth/slice'
import RequireAuth from './components/RequireAuth'
import AuthRoute from './components/AuthRoute'
import Layout from './components/AppLayout'

import ViewProfile from './features/profile/View'
import { getProfileAsync } from './features/profile/slice'
import Company from './features/company/Company'

function App() {
  const dispatch = useDispatch()

  useEffect(() => {
    const auth = getAuth()
    onAuthStateChanged(auth, (user) => {
      if (user) {
        dispatch(setUser(user))
        dispatch(getProfileAsync())
      } else {
        dispatch(setUser(null))
      }
    })
  }, [dispatch])
  return (
    <Router>
      <Layout>
        <Routes>
          <Route element={<RequireAuth />}>
            <Route exact path="/logout" element={<Logout />} />
            <Route path="/profile" element={<ViewProfile />} />
            <Route path="/">
              <Route path="" element={<ListCompanies />} />
              <Route path="company/:companyID" element={<Company />} />
              <Route path="company/:companyID/user" element={<ListUsers />} />
              <Route path="contact-us" element={<ListContactUs />} />
            </Route>
          </Route>
          <Route
            path="/login"
            element={
              <AuthRoute>
                <Login />
              </AuthRoute>
            }
          />
          <Route
            path="register"
            element={
              <AuthRoute>
                <Register />
              </AuthRoute>
            }
          />
        </Routes>
      </Layout>
    </Router>
  )
}

export default App
