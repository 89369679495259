import { axiosClient } from './base'

const LIST_CONTACTS = '/admin/api/contact'

export const listContacts = ({ pageNumber, pageSize, q }) =>
  axiosClient.get(LIST_CONTACTS, {
    params: {
      pageNumber,
      pageSize,
      q
    }
  })
