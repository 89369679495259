import React from 'react'
import MuiTableCell from '@mui/material/TableCell'
import { styled } from '@mui/material/styles'

const TableCell = styled(MuiTableCell)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    display: 'none'
  }
}))

export default function CustomCell (props) {
  const { children, ...rest } = props
  return (
    <TableCell
      style={{
        textAlign: 'center'
      }}
      sx={{
        bgcolor: 'white',
        color: 'text.black',
        borderBottom: '1px solid #D8D8D8',
        py: { xs: 1, md: '13px' },
        px: { xs: 3, md: 3 },
        fontSize: { xs: '12px', md: '14px' }
      }}
      {...rest}
    >
      {children}
    </TableCell>
  )
}
