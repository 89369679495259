import React from 'react'
import { makeStyles } from '@mui/styles'
import CssBaseline from '@mui/material/CssBaseline'
import Box from '@mui/material/Box'
import { useSelector } from 'react-redux'
import { Toaster } from 'react-hot-toast'

import { selectCurrentUser } from '../features/auth/selectors'
import Appbar from './Appbar'
import Sidebar from './Sidebar'
import { COLORS } from '../constants'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    height: '100%'
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar
  },
  content: {
    flexGrow: 1,
    // padding: theme.spacing(1),
    // minHeight: "101vh",
    backgroundColor: COLORS.background,
    // height: '100vh'
    // paddingLeft: { xs: '16px', sm: '20px', md: '70px' },
    // paddingRight: { xs: '16px', sm: '20px', md: '85px' },
    height: '100%',
    overflow: 'scroll'
  }
}))

export default function AppLayout ({ children }) {
  const classes = useStyles()

  const [open, setOpen] = React.useState(false)

  const handleDrawerOpen = () => {
    setOpen(true)
  }

  const handleDrawerClose = () => {
    setOpen(false)
  }

  // The correct way is to add it to the Outlet but let's fix it later
  const currentUser = useSelector(selectCurrentUser)
  if (!currentUser) {
    return <>{children}</>
  }

  return (
    <div className={classes.root}>
      <CssBaseline />

      <Appbar open={open} handleDrawerOpen={handleDrawerOpen} />
      <Sidebar open={open} handleDrawerClose={handleDrawerClose} />
      <Toaster />
      <Box
        component="main"
        className={classes.content}
        sx={{
          // paddingLeft: { xs: '12px', sm: '24px' },
          // paddingRight: { xs: '12px', sm: '24px' }
          overflowX: 'hidden',
          backgroundColor: 'background.third'
        }}
        >
        <div className={classes.toolbar} />
        {children}
      </Box>
    </div>
  )
}
