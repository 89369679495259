import { Divider, Grid } from '@mui/material'
import PageHeader from '../../components/PageHeader'
import ManageAccountSection from './ManageAccountSection'
import ManagePasswordSection from './ManagePasswordSection'
import ManageCompanySection from './ManageCompanySection'
import { useDispatch } from 'react-redux'
import { useEffect } from 'react'
import { getProfileAsync, listCompanyUsersAsync } from './slice'
import CompanyUsers from './CompanyUsersSection'

export default function ViewProfile () {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getProfileAsync())
    dispatch(listCompanyUsersAsync())
  }, [])
  return (
    <>
      <PageHeader title={'ادارة الحساب'} backPath={'/'} />

      <Grid container spacing={3} mt={0.5}>
        <Grid item xs={6}>
          <Grid container spacing={2} alignItems={'center'}>
            <ManageAccountSection />
            <Grid item xs={12}>
              <Divider
                sx={{
                  mt: 2
                }}
              />
            </Grid>
            <ManagePasswordSection />
            <Grid item xs={12}>
              <Divider
                sx={{
                  mt: 2
                }}
              />
            </Grid>
            <ManageCompanySection />
          </Grid>
        </Grid>
        <Grid item xs={1} display={'flex'} justifyContent={'center'}>
          <Divider orientation="vertical" />
        </Grid>
        <Grid item xs={5}>
          <CompanyUsers />
        </Grid>
      </Grid>
    </>
  )
}
