import { Grid, Typography } from '@mui/material'
import { Box } from '@mui/system'
import ContactUsTable from './ContactUsTable'

export default function List () {
  return (
    <>
      <Box
        sx={{
          pl: { xs: '16px', md: '24px', lg: '24px' },
          pr: { xs: '16px', md: '24px', lg: '24px' },
          mb: '40px',
          position: 'relative'
        }}
      >
        <Grid
          container
          padding={0}
          justifyContent="space-between"
        >
          <Grid item xs={12} marginBottom={3} marginTop={3}>
            <Typography
              sx={{ fontSize: '20px', fontWeight: 'bold', color: 'text.black' }}
            >
              رسائل تواصل معنا
            </Typography>
          </Grid>
        </Grid>

        <ContactUsTable />
      </Box>
    </>
  )
}
